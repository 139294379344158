import { Cookies } from "react-cookie";

const cookies = new Cookies();

export const setCookie = (name, value, Option) => {
  return cookies.set(name, value, { ...Option });
};

export const getCookie = (name) => {
  return cookies.get(name);
};

// 쿠키 삭제
export const removeCookie = (name, options = { path: '/' }) => {
  return cookies.remove(name, { ...options });
};