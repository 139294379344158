import axios from "axios";
import {getCookie} from "../util/Cookie";
import { TokenName } from "../util/Common";

export const API_URL = "https://moha.ai";
// export const API_URL = "http://211.188.48.212"
// CEAZER1234!!@
const api = axios.create({
    baseURL: API_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

// 응답 인터셉터 

api.interceptors.response.use(
    (response) => response,
    (error) => {
        console.error("API Error:", error);
        if (error.response) {
            console.log("Status:", error.response.status);
            console.log("Data:", error.response.data);

          
        } else if (error.request) {
            console.error("No response received");
        }
        if (error.config?.headers?.errorpage) {
            window.location.href = "/error";
        }
        return Promise.reject(error);
    }
);

api.interceptors.request.use(
    (config) => {
        const token = getCookie(TokenName);
        // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxLCJleHAiOjE3MzY0MDg2OTl9.uT5SotoouquybRybPomWhoILZLkm8NKctRRr6tJmw9o";

        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        
        return config;
    },
    (error) => {
        
        return Promise.reject(error);
    }
)
export default api;