import { create } from 'zustand';
import { persist } from 'zustand/middleware';  // persist 미들웨어를 import

export const useConceptsStore = create(
  persist(
    (set) => ({
      concepts: [],
      setConcepts: (newConcepts) => set((state) => {
        const uniqueConcepts = newConcepts.filter(newItem => 
          !state.concepts.some(existingItem => existingItem.id === newItem.id)
        );
        return {
          concepts: [...state.concepts, ...uniqueConcepts],
        };
      }),
      newSetConcepts: (newConcepts) => set({ concepts: newConcepts }),  // 데이터 덮어쓰기
    }),
  
    {
      name: 'concepts-storage', // 로컬스토리지의 키
      getStorage: () => localStorage, // 로컬스토리지 사용
    }
  )
);
