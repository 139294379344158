import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import { GlobalStyle } from './app/layout/GlobalStyle';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <>
  <GlobalStyle/>
    <App />
  </>
  
  // </React.StrictMode>
);


