import axios from "axios";
import { useEffect, useState } from "react";

import { useNavigate } from "react-router";



import { useTranslation } from "react-i18next";
import { API_URL } from "../../lib/api/apiConfig";
import { setCookie } from "../../lib/util/Cookie";
import { TokenExpire, TokenName } from "../../lib/util/Common";
import { fetchMydata } from "../../lib/api/login/GetMyData";
import { useLoginStore } from "../../store/login/LoginStore";
import { useQuery } from "@tanstack/react-query";
import { useURLStore } from "../../store/url/UrlStore";

const KakaoRedirectURL = () => {
  const { t, i18n } = useTranslation();
  const language = useURLStore((state) => state.language); 

  const navigate = useNavigate();
  const { data, isLoading, isError, error ,refetch } = useQuery({
      queryKey: ['mydata'], 
      queryFn: fetchMydata, 
      keepPreviousData: true, 
      enabled:false, // 이전 데이터를 유지하여 빠르게 화면을 갱신하도록 설정
    });
  const { setMydata,setFirstLogin} = useLoginStore(); 
    const [firstLogin2,setFirstLogin2] = useState(false);
  useEffect(()=>{
      if (data){
          setMydata(data)
          setFirstLogin(firstLogin2)
          navigate(`/${language}/`)
      }
  },[data])

  useEffect(() => {
    const fetchAuthData = async () => {


      try {
        const code = new URL(window.location.href).searchParams.get("code");
        const state = new URL(window.location.href).searchParams.get("state");
        const resp = await axios.post(
          `${API_URL}/api/v1/auth/kakao/login?code=${code}&state=${state}`
        );
        const tokenData = resp.data;
        if (resp.status == 200) {
            setCookie(TokenName, tokenData.access_token, { path: "/", maxAge: TokenExpire });
            refetch()
            setMydata(data)
            setFirstLogin(tokenData.is_first_login)
            setFirstLogin2(tokenData.is_first_login)
        } else {
         
          return;
        }
      } catch (error) {
        console.error("error", error)
        navigate(`/${language}/login`)
      }
    };
    fetchAuthData();
  }, []);
  return (<div></div>);
};

export default KakaoRedirectURL;