import api from "../apiConfig"



export const ResourcesAPI = async () => {
    try {
        const resp = await api.get('api/v1/resources/balance');
        return resp;
    } catch (error) {
        console.log("리소스 가져오기 실패", error)
        return error;
    }
}

export const LogsStatusAPI = async () => {

    try {

        const resp = await api.get('api/v1/mypage/logs/status');
        return resp;
    } catch (error) {

        console.log("로그호출 에러", error);
        return error;
    }
}

export const ImgStatusAPI = async (task_id) => {

    try {

        const resp = await api.get('api/v1/mypage/logs/img_download', {
            params: {
                task_id: task_id
            }
        });
    } catch(error) {
        console.log("이미지 로그 남기기 실패", error);
    }
}

export const LogURLAPI = async (url) => {

    try {

        const resp = await api.get('api/v1/mypage/logs', {
            params: {
                url: url
            }
        });
    } catch(error) {
        console.log("URL 로그 남기기 실패", error)
    }
}