import { useURLStore } from "../../store/url/UrlStore";
import LoginHeader from "../home/components/LoginHeader"
import { Table, Td, Th } from "./PolicyStyle"



export const PrivacyPolicyPage = () => {

    const languageState = useURLStore((state) => state.language); 

    return(
        <div className="flex flex-col min-h-screen px-4">
        <LoginHeader menu={true}></LoginHeader>

        <div>
        <div className="container mx-auto py-8 px-4 max-w-6xl">
      <div className="text-2xl font-bold mb-12 text-left">
                  
                  {languageState === 'ko' ? (
                    <>
                      <div className="">
  <h3 className="">MOHA 개인정보 처리방침</h3>
</div>
<div className="">
  <p className="mb-3">
     MOHA을 운영하는 주식회사 엑스알에이아이(이하
    “회사”라고합니다)는 회원님들의 개인정보를 소중히 다루고
    있습니다. 
  </p>
  <p className="mb-3">
     회사는 정보통신망 이용 촉진 및 정보 보호 등에 관한 법률을
    비롯하여 회사가 준수하여야 할 관련 법령상의 개인 정보보호
    규정을 준수하고 관련 법령에 의거한 개인정보 처리방침을
    통해 회원님의 개인 정보가 보호받을 수 있도록 최선을 다하고
    있습니다. 개인 정보 처리 방침 및 개인 정보 보호 정책을
    통해 이용자가 제공한 개인 정보가 어떠한 용도와 방식으로
    이용되며, 개인 정보 보호를 위해 어떻게 관리되고 있는지에
    대해 안내드립니다. 
  </p>
  <p className="mb-3">
    본 개인정보 처리방침은 2024년 12월 02일부터 시행됩니다.
  </p>
  <h5 className="mb-3"> 1. 수집하는 개인정보 항목</h5>
  <p className="mb-0">  ● 필수항목</p>
  <p className="mb-0">
          이름, 생년월일, 성별, 로그인 ID, 비밀번호, 주소,
    휴대전화번호, 이메일, 쿠키, 결제기록
  </p>
  <p className="mb-0">  ● 수집 방법</p>
  <p className="mb-3">      홈페이지 회원가입을 통해 수집</p>
  <h5 className="mb-3"> 2. 수집하는 개인정보 이용목적</h5>
  <p className="mb-3">
      회사는 수집한 개인정보를 다음과 같은 목적을 위해
    활용합니다.  
  </p>
  <p className="mb-0">  ● 회원 가입 및 관리</p>
  <p className="mb-0">
          회원가입 의사 확인, 회원제 서비스 제공에 따른 본인
    식별 및 인증, 회원 자격 유지 및 관리, 서비스 부정이용
    방지, 불량 이용회원에 대한 이용제재, 법정 대리인 동의 여부
    확인 등
  </p>
  <p className="mb-0">  ● 재화 또는 서비스 제공</p>
  <p className="mb-0">
          서비스 제공에 관한 계약 이행 및 서비스 제공에 따른
    요금 정산 및 콘텐츠 제공, 구매 및 요금 결제, 물품 배송
    또는 청구서 등의 발송, 본인인증, 구매, 요금 결제, 요금
    추심, 국세청 소득신고 혹은 세금계산서 발행, 법정 대리인의
    동의여부확인 등
  </p>
  <p className="mb-0">  ● 고충 처리</p>
  <p className="mb-3">
          민원인의 신원확인, 민원사항 확인, 사실조사를 위한
    연락 및 처리결과 통보 혹은 통지, 분쟁 조정을 위한 기록
    보존, 고지사항 전달, 본인 의사 확인, 불만 처리 등 원활한
    의사소통 경로의 확보
  </p>
  <h5 className="mb-3"> 3. 개인정보 처리 및 보유기간</h5>
  <p className="mb-3">
      회사는 회원으로부터 수집한 개인정보에 대해 회원자격이
    유지되는 기간 내에서 개인정보를 처리 및 보유하며,
    개인정보의 처리 목적이 달성되면 지체없이 파기합니다. 다만,
    회원에게 사전동의를 받았거나 관련 법령에 의거하여 보존할
    필요성이 있는 경우에는 일정 기간 동안 보유합니다. 현행법상
    이와 관련된 법률은 다음과 같습니다.  
  </p>
  <p className="mb-0">
      1) 전자상거래 등에서의 소비자 보호법에 관한 법률
  </p>
  <p className="mb-0">
          ① 계약 또는 청약철회등에 관한 기록(5년)
  </p>
  <p className="mb-0">
          ② 대금 결제 및 재화 등의 공급에 관한기록(5년)
  </p>
  <p className="mb-0">
          ③ 소비자의 불만 또는 분쟁처리에 관한 기록(3년)
  </p>
  <p className="mb-0">      ④ 표시/광고에 관한 기록(6개월)</p>
  <p className="mb-0">  2) 전자 금융 거래법</p>
  <p className="mb-0">      ① 전자금융에 관한 기록(5년)</p>
  <p className="mb-0">  3) 통신비밀 보호법</p>
  <p className="mb-0">      ① 로그인 기록(3개월)</p>
  <p className="mb-0">  4) 회원의 동의를 받은 경우</p>
  <p className="mb-0">      ① 회원의 동의를 받은 기간까지</p>
  <p className="mb-0">
     5) 회원의 동의 및 관계 법령과 무관하게, 아래 경우에
    해당하는 경우 해당 사유 종료 시까지 개인정보를 보유할 수
    있습니다.
  </p>
  <p className="mb-0">
          ① 관계 법령 위반에 따른 수사, 조사 등이 진행 중인
    경우 해당 수사, 조사 종료 시까지
  </p>
  <p className="mb-3">
          ② 회사의 서비스 이용에 따른 채권, 채무관계 잔존 시
    해당 채권, 채무관계 정산 시까지
  </p>
  <h5 className="mb-3"> 4. 개인정보의 제3자 제공</h5>
  <p className="mb-3">
     회사는 회원의 개인정보를 처리 목적에 명시한 범위 내에서만
    처리합니다. 다만, 아래와 같이 회원의 동의, 법률의 특별한
    규정 등에 해당하는 경우에는 개인정보를 제 3자에게
    제공합니다.
  </p>
  <p className="mb-0"> 1) 회원이 사전에 동의한 경우</p>
  <p className="mb-0">
     2) 법령의 규정에 의거하거나 수사 목적으로 법령에 정해진
    절차와 방법에 따라 수사기관의 요구가 있는 경우
  </p>
  <p className="mb-0">
      3) 법률 등에 특별한 규정이 있는 경우
  </p>
  <p className="mb-0">
      4) 회원의 서비스 이용에 따른 정산이 필요한 경우
  </p>
  <p className="mb-0">
      5) 사람의 생명 및 신체에 긴급한 위험이 발생한 경우에
    정보주체의 동의를 받을 수 없는 정당한 사유가 있는 경우
  </p>
  <p className="mb-3">
      6) 통계 작성 및 학술연구 등의 목적을 위하여 필요한
    경우로서 특정 개인을 알아 볼 수 없는 형태로 개인 정보를
    제공하는 경우
  </p>
  <h5 className="mb-3"> 5. 개인정보 처리 위탁</h5>
  <p className="mb-3">
     회사는 원활한 업무 처리를 위하여 회원이 동의한 목적 또는
    계약의 체결, 유지, 이해, 관리 등의 목적으로 아래와 같이
    개인정보 처리 업무를 전문업체에 위탁하고 있습니다.
  </p>
  <Table>
    <thead>
      <tr>
        <Th>수탁 업체</Th>
        <Th>위탁업무 내용</Th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <Td>페이레터 주식회사</Td>
        <Td>
          신용카드 결제 승인 및 매입 업무 대행, 계좌이체,
          간편결제(네이버페이/카카오페이/토스페이), 본인인증
        </Td>
      </tr>
      <tr>
        <Td>주식회사 카카오페이</Td>
        <Td>
          카카오페이 결제 대행 및 현금영수증 신청접수 위탁,
          본인인증
        </Td>
      </tr>
      <tr>
        <Td>(주)비바리퍼블리카</Td>
        <Td>
          Toss 결제 대행 및 현금 영수증 신청접수 위탁,
          본인인증
        </Td>
      </tr>
    </tbody>
  </Table>
  <h5 className="mb-3"> 6. 개인정보의 파기 절차 및 방법</h5>
  <p className="mb-0">
     회사는 개인정보 보유기간의 경과, 처리 목적 달성 등
    개인정보가 필요하지 않게 되었을 때에는 지체없이 해당
    개인정보를 파기합니다. 다만, 회원으로부터 동의 받은
    개인정보 보유기간이 경과하거나 처리 목적이 달성되었음에도
    불구하고 다른 사유 혹은 법령에 따라 개인정보를 계속
    보존하여야 하는 아래 경우에 해당할 경우 해당 개인정보를
    별도의 데이터베이스로 옮기거나 물리적인 보관 장소를
    달리하여 보존합니다.
  </p>
  <p className="mb-0">
          1) 회사가 민/형사상의 책임 또는 시효가 지속 되거나
    분쟁의 입증자료로서 개인정보를 보유하는 경우
  </p>
  <p className="mb-0">
          2) 상법 제33조 등 법령에 따라 보존하여야 하는 경우
  </p>
  <p className="mb-3">
          3) 기타 이와 유사한 정당한 사유가 있는 경우
  </p>
  <p className="mb-0">  ● 파기 방법</p>
  <p className="mb-3">
    {" "}
          전자적 파일 형태로 기록 및 저장된 개인정보는 기록을
    재생할 수 없도록 파기하며, 종이 문서에 기록 및 저장된
    개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.
  </p>
  <h5 className="mb-3">
     7. 회원 및 법정대리인의 권리와 행사방법
  </h5>
  <p className="mb-0">
      회원 및 법정대리인(만 14세미만 회원의 경우)은 회사에
    대해 언제든지 아래에 대한 개인정보 보호 관련 권리를 행사할
    수 있습니다.
  </p>
  <p className="mb-0">   1) 개인정보 열람 요구</p>
  <p className="mb-0">   2) 오류 등이 있을 경우 정정 요구</p>
  <p className="mb-0">
      3) 삭제 요구(다만, 다른 법령에서 해당 개인정보
    수집대상으로 명시되어 있는 경우에는 해당 삭제 요구가
    받아들여지지 않을 수 있습니다.)
  </p>
  <p className="mb-3">
      4) 탈퇴 혹은 개인정보 처리 중지 요구(서비스 일부, 전체
    이용이 어려울 수 있습니다.)
  </p>
  <h5 className="mb-3"> 8. 아동의 개인정보 보호</h5>
  <p className="mb-3">
      회사는 “정보통신망이용 촉진 및 정보보호등에 관한 법률
    제31조 제 1항”에 의하여 만 14세 미만의 아동의 개인정보
    수집시 법정대리인의 동의를 받아야합니다. 따라서, 당사는 만
    14세 미만의 아동에 대해서는 개인정보를 받지 않은 뿐만
    아니라, 회원으로 가입되지 않습니다. 단, 14세 미만의 아동은
    법정대리인의 동의 및 서면 인증 후에, 회원으로 가입할 수
    있습니다. 또한, 만 14세 미만의 아동의 법정대리인은 아동의
    개인정보의 열람, 정정, 동의철회를 요청할 수 있으며 이런
    요청이 있을 경우 회사는 지체없이 필요한 조치를 취합니다.
  </p>
  <h5 className="mb-3">
     9. 개인정보 자동 수집 장치의 설치, 운영 및 그 거부에 관한
    사항
  </h5>
  <p className="mb-0">
      회사는 회원의 정보를 수시로 저장하고 찾아내는
    쿠키(cookie) 등 개인정보를 자동으로 수집하는 장치 혹은
    기술을 설치/운용합니다. 쿠키란 회사의 웹사이트를
    운영하는데 이용되는 서버가 회원님의 웹 브라우저에 보내는
    작은 텍스트로, 회원의 컴퓨터에 저장됩니다.
  </p>
  <p className="mb-0">
      회사는 쿠키 등을 다음과 같은 목적을 위해 사용합니다.
  </p>
  <p className="mb-0">
      1) 회사가 제공하는 상품, 콘텐츠, 결제시 인증
  </p>
  <p className="mb-0">
      2) 유료 서비스 이용 시 이용 기간 안내
  </p>
  <p className="mb-0">
      3) 이벤트 및 설문조사 시 참여 내역 등 활동내역 확인
  </p>
  <p className="mb-0">
      4) 이용자의 관심 분야에 따라 차별화된 정보제공
  </p>
  <p className="mb-0">
      5) 이용자의 이용패턴 분석을 통한 서비스 개편의 척도로
    활용
  </p>
  <p className="mb-0">
      단, 회원은 쿠키설치 및 이용권한에 대해 선택권을 가지고
    있으며, 직접 사용 중인 웹 브라우저에서 옵션을 설정함으로서
    웹 브라우저가 지원하는 설정에 따라 모든 쿠키를 허용하거나,
    쿠키가 저장될 때마다 확인을 거치거나, 모든 쿠키의 저장을
    거부할 수 있습니다.
  </p>
  <p className="mb-3">
      단, 회원님께서 쿠키 저장을 거부하였을 경우 로그인이
    필요한 서비스는 이용이 제한됩니다.
  </p>
  <h5 className="mb-3">
     10. 개인정보의 기술적/관리적 보호대책
  </h5>
  <p className="mb-0">
      회사는 회원의 개인정보를 취급함에 있어 개인정보가 분실,
    도난, 누출, 변조 또는 훼손되지 않도록 기술적인 관리적 보호
    조치를 위하여 다음과 같은 대책을 강구하고 있습니다.
  </p>
  <p className="mb-3">      1) 접근 통제 및 권한의 권리</p>
  <p className="mb-0">
      회사는 개인정보에 대해 접근권한을 업무수행에 필요한
    최소한의 범위로 합니다. 각 업무 담당자에게 각 한 개의
    계정을 지급하여 권한을 차등부여하고 있으며, 개인정보
    취급자 및 담당 업무가 변경되었을 경우 개인정보에 대한
    접근권한을 변경 또는 말소하고 있습니다. 또한, 개인정보
    책임자 및 취급자가 외부에서 개인정보에 접근하려는 경우
    VPN, 보안 연결 등 안전한 접속수단을 사용하고 있으며,
    외부인 등 권한이 없는 자가 개인정보를 열람할 수 없도록
    개인정보 조회 및 처리를 위한 시스템과 개인PC에 조치를
    취하고 있습니다.
  </p>
  <p className="mb-3">      2) 개인정보 암호화</p>
  <p className="mb-0">
      회사는 정보통신망 혹은 보조 저장매체를 통하여
    송/수신하는 모든 개인정보에 대해 반드시 암호화하여
    처리하고 있으며, 가급적 업무용 PC에 개인정보를 보관하지
    않고 부득이한 사유로 보관해야 하는 경우 해당 데이터를
    암호화하여 안전하게 보관/처리하고 있습니다. 또한,
    개인정보보호법에 의거하여 고유식별 번호(주민등록번호,
    외국인 등록번호 등), 비밀번호 및 민감한 개인정보 등은
    안전한 알고리즘으로 암호화하여 저장, 보관하고 있습니다.
  </p>
  <p className="mb-3">
          3) 접속기록의 보관 및 위변조 방지와 물리적 조치
  </p>
  <p className="mb-0">
      회사는 개인정보 취급자가 시스템상으로 개인정보를 처리한
    기록을 최소 3개월 이상 보관하며 해당 기록이 위변조 및
    도난, 분식, 삭제되지 않도록 안전하게 보관하고 있습니다.
    또한, 서버 등 개인정보를 보관하고 있는 물리적 보관 장소의
    경우 임의로 출입할 수 없는 장소로 지정하여 운영하고
    있으며, 개인정보가 포함된 서류, 전자 문서, 보조 저장매체
    등을 잠금장치 등으로 보호받는 안전한 장소에 보관하고
    있습니다.
  </p>
  <p className="mb-3">
          11. 개인정보에 관한 민원 서비스
  </p>
  <p className="mb-3">
      회사는 회원의 개인정보를 보호하고 개인정보와 관련한
    불만을 처리하기 위해 아래와 같이 개인정보 보호 책임자를
    지정하고 있습니다. 개인정보 열람, 정정 요청 등 개인정보와
    관련된 문의사항에 대해서는 아래 연락처를 통해 연락할 수
    있습니다.
  </p>
  <p className="mb-3">
      1) 개인정보보호 책임자 : 이승석 이사 / Email :
    dltmdtjr9683@xrai-api.com
  </p>
  <p className="mb-3">
      회원은 회사의 서비스를 이용하시면서 발생하는 모든
    개인정보보호 관련 민원을 개인정보관리책임자를 통해
    신고하실 수 있습니다.
  </p>
  <p className="mb-3">  12. 권익 침해 구제방법</p>
  <p className="mb-0">
      회원은 아래의 기관에 개인정보 침해에 대한 피해구제, 상담
    등을 문의하실 수 있습니다.
  </p>
  <p className="mb-0">
      1) 개인정보 분쟁조정위원회 (전화 1833-6972) /
    www.kopico.go.kr
  </p>
  <p className="mb-0">
      2) 개인정보 침해신고센터 (국번없이 118) /
    privacy.kisa.or.kr
  </p>
  <p className="mb-0">
      3) 대검찰청 사이버범죄수사단 (02-3480-3571) /
    www.spo.go.kr
  </p>
  <p className="mb-3">
      4) 경찰청 사이버안전국 (국번없이 182) /
    cyberbureau.police.go.kr
  </p>
  <h5 className="mb-3">
     13. 본 개인정보 처리방침의 적용범위
  </h5>
  <p className="mb-3">
      본 개인정보 처리방침은 회사의 서비스 중 하나인 MOHA 및
    관련 제반 서비스에 적용되며, 다른 브랜드로 제공되는
    서비스에 대해서는 별개의 개인정보 처리방침이 적용될 수
    있습니다. 또한, MOHA 서비스에 링크되어있는 다른 회사의
    웹/앱에서 개인정보를 수집하는 경우, 해당 앱/웹의 개인정보
    처리방침을 따릅니다.
  </p>
  <h5 className="mb-3">
     14. 개인정보 처리방침의 변경에 관한 사항
  </h5>
  <p className="mb-3">
      개인정보 처리방침의 내용추가, 삭제, 변경이 있을 경우
    가급적 7일 전에 사전 고지를 진행하고, 만약 수집 개인정보의
    항목, 이용목적의 변경 등 회원 권리의 중대한 변경이
    발생하는 경우 최소 30일 전에 고지하겠습니다.
  </p>
</div>
                    </>

                  ) : (
                    <div>
                    <div className="">
                      <h1>MOHA Privacy Notice</h1>
                    </div>
                    <div className="">
                      <p>
                        XRAI Co., Ltd. (hereinafter referred to as the “Company”),
                        which operates MOHA, takes great care in handling our
                        members’ personal information.
                      </p>
                      <p>
                        The Company complies with personal information protection
                        regulations under relevant laws, including the Act on
                        Promotion of Information and Communications Network
                        Utilization and Information Protection, and strives to
                        protect our members' personal information through privacy
                        policies in accordance with relevant laws. Through our
                        privacy policy and personal information protection policy,
                        we inform you about how the personal information provided
                        by users is used, and how it is managed for personal
                        information protection.
                      </p>
                      <p>
                        This Privacy Policy is effective as of December 02, 2024.
                      </p>
  
                      <h2 style={{marginTop: `20px`, marginBottom: `20px`}}>1. Collection of Personal Information</h2>
                      <p>
                        The Company collects minimum personal information through
                        its website as follows during the membership registration
                        or service use process:
                      </p>
                      <ul>
                        <li>
                          <strong>Required Information:</strong> Name, date of
                          birth, gender, login ID, password, address, mobile phone
                          number, email, cookies, payment records
                        </li>
                        <li>
                          <strong>Collection Method:</strong> Collection through
                          website membership registration
                        </li>
                      </ul>
  
                      <h2 style={{marginTop: `20px`, marginBottom: `20px`}}>2. Purpose of Personal Information Use</h2>
                      <p>
                        The Company uses collected personal information for the
                        following purposes:
                      </p>
                      <ul>
                        <li>
                          <strong>Member Registration and Management:</strong>{" "}
                          Verification of membership registration intent,
                          identification and authentication for membership
                          services, maintenance and management of membership
                          status, prevention of service misuse, sanctions against
                          malicious users, verification of legal guardian consent,
                          etc.
                        </li>
                        <li>
                          <strong>Provision of Goods or Services:</strong>{" "}
                          Fulfillment of service provision contracts, fee
                          calculation for service provision, content provision,
                          purchase and payment processing, shipping of goods or
                          sending of invoices, identity verification, purchase,
                          payment processing, fee collection, income tax filing or
                          tax invoice issuance, verification of legal guardian
                          consent, etc.
                        </li>
                        <li>
                          <strong>Handling Complaints:</strong> Verification of
                          complainant identity, confirmation of complaints,
                          contact for investigation and notification of processing
                          results, preservation of records for dispute mediation,
                          delivery of notices, confirmation of intentions,
                          handling complaints, and securing smooth communication
                          channels
                        </li>
                      </ul>
  
                      <h2 style={{marginTop: `20px`, marginBottom: `20px`}}>
                        3. Processing and Retention Period of Personal Information
                      </h2>
                      <p>
                        The Company processes and retains personal information
                        collected from members during the period of membership,
                        and promptly destroys it when the purpose of processing
                        personal information is achieved. However, information may
                        be retained for a certain period if prior consent has been
                        obtained from members or if preservation is necessary
                        according to relevant laws. Current laws related to this
                        include:
                      </p>
                      <ul>
                        <li>
                          <strong>
                            Act on Consumer Protection in Electronic Commerce,
                            etc.:
                          </strong>
                          <ul>
                            <li>
                              Records on contracts or withdrawal of subscription,
                              etc. (5 years)
                            </li>
                            <li>
                              Records on payment and supply of goods, etc. (5
                              years)
                            </li>
                            <li>
                              Records on consumer complaints or dispute handling
                              (3 years)
                            </li>
                            <li>Records on display/advertising (6 months)</li>
                          </ul>
                        </li>
                        <li>
                          <strong>Electronic Financial Transactions Act:</strong>{" "}
                          Records on electronic financial transactions (5 years)
                        </li>
                        <li>
                          <strong>
                            Protection of Communications Secrets Act:
                          </strong>{" "}
                          Login records (3 months)
                        </li>
                        <li>
                          When consent is obtained from members: Until the period
                          agreed upon by members
                        </li>
                        <li>
                          Regardless of member consent and related laws, personal
                          information may be retained until the end of the
                          relevant cause in the following cases:
                          <ul>
                            <li>
                              Until the completion of investigation or examination
                              in case of ongoing investigation due to violation of
                              relevant laws
                            </li>
                            <li>
                              Until the settlement of credits and debts arising
                              from the use of Company services
                            </li>
                          </ul>
                        </li>
                      </ul>
  
                      <h2 style={{marginTop: `20px`, marginBottom: `20px`}}>
                        4. Provision of Personal Information to Third Parties
                      </h2>
                      <p>
                        The Company processes personal information only within the
                        scope specified in the processing purpose. However,
                        personal information may be provided to third parties in
                        the following cases:
                      </p>
                      <ul>
                        <li>When prior consent is obtained from members</li>
                        <li>
                          When required by law or requested by investigative
                          agencies for investigation purposes following procedures
                          and methods prescribed by law
                        </li>
                        <li>When there are special provisions in laws</li>
                        <li>
                          When settlement is required for member's service use
                        </li>
                        <li>
                          When there are legitimate reasons preventing obtaining
                          consent from the information subject in cases of urgent
                          risk to life and body
                        </li>
                        <li>
                          When providing personal information in a form that
                          cannot identify specific individuals for statistical
                          compilation and academic research purposes
                        </li>
                      </ul>
  
                      <h2 style={{marginTop: `20px`, marginBottom: `20px`}}>5. Outsourcing of Personal Information Processing</h2>
                      <p style={{marginTop: `20px`, marginBottom: `20px`}}>
                        The Company outsources personal information processing to
                        professional companies as follows for smooth business
                        operations, with the consent of members or for purposes of
                        contract conclusion, maintenance, understanding, and
                        management:
                      </p>
                      <table style={{marginTop: `20px`, marginBottom: `20px`}} border="1" cellpadding="5">
                        <thead>
                          <tr>
                            <th>Contractor</th>
                            <th>Tasks</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Payletter Co., Ltd.</td>
                            <td>
                              Credit card payment approval and purchase agency,
                              account transfer, simple payment (Naver Pay/Kakao
                              Pay/Toss Pay), identity verification
                            </td>
                          </tr>
                          <tr>
                            <td>KakaoPay Co., Ltd.</td>
                            <td>
                              Kakao Pay payment agency and cash receipt
                              application entrustment, identity verification
                            </td>
                          </tr>
                          <tr>
                            <td>Viva Republica Co., Ltd.</td>
                            <td>
                              Toss payment agency and cash receipt application
                              entrustment, identity verification
                            </td>
                          </tr>
                          <tr>
                            <td>PayPal</td>
                            <td>
                              PayPal payment agency and identity verification
                            </td>
                          </tr>
                        </tbody>
                      </table>
  
                      <h2 style={{marginTop: `20px`, marginBottom: `20px`}}>
                        6. Procedures and Methods for Destroying Personal
                        Information
                      </h2>
                      <p>
                        The Company destroys personal information without delay
                        when it is no longer necessary due to the expiration of
                        the retention period or achievement of the processing
                        purpose. However, if personal information must continue to
                        be preserved for other reasons or laws despite the
                        expiration of the retention period or achievement of the
                        processing purpose, the Company moves such information to
                        a separate database or stores it in a different physical
                        location in the following cases:
                      </p>
                      <ul>
                        <li>
                          When the Company retains personal information as
                          evidence for civil/criminal liability or ongoing statute
                          of limitations or disputes
                        </li>
                        <li>
                          When preservation is required by laws such as Article 33
                          of the Commercial Act
                        </li>
                        <li>Other similar legitimate reasons</li>
                      </ul>
                      <p>
                        <strong>Destruction Method:</strong> Personal information
                        recorded and stored in electronic file format is destroyed
                        in a manner that prevents reproduction of records, and
                        personal information recorded and stored in paper
                        documents is destroyed by shredding or incineration.
                      </p>
  
                      <h2 style={{marginTop: `20px`, marginBottom: `20px`}}>
                        7. Rights of Members and Legal Representatives and Their
                        Exercise
                      </h2>
                      <p>
                        Members and legal representatives (in case of members
                        under 14) can exercise the following personal information
                        protection rights at any time:
                      </p>
                      <ul>
                        <li>Request to view personal information</li>
                        <li>Request for correction in case of errors</li>
                        <li>
                          Request for deletion (however, such requests may not be
                          accepted if the personal information is specified as a
                          collection target in other laws)
                        </li>
                        <li>
                          Request for withdrawal or suspension of personal
                          information processing (some or all services may be
                          difficult to use)
                        </li>
                      </ul>
                      <p>
                        Viewing registered personal information is possible after
                        completing identity verification procedures (or legal
                        representative verification) as determined by the Company.
                        Withdrawal is also possible after completing identity
                        verification through the Company's prescribed procedures.
                      </p>
  
                      <h2 style={{marginTop: `20px`, marginBottom: `20px`}}>8. Protection of Children’s Personal Information</h2>
                      <p>
                        According to Article 31, Paragraph 1 of the Act on
                        Promotion of Information and Communications Network
                        Utilization and Information Protection, the Company must
                        obtain consent from legal representatives when collecting
                        personal information of children under 14 years of age.
                        Therefore, the Company does not collect personal
                        information from children under 14 years of age, nor do we
                        allow them to become members. However, children under 14
                        may become members after obtaining consent and written
                        certification from their legal representatives.
                        Additionally, legal representatives of children under 14
                        can request to view, correct, or withdraw consent for the
                        child's personal information, and the Company will take
                        necessary measures without delay when such requests are
                        made.
                      </p>
  
                      <h2 style={{marginTop: `20px`, marginBottom: `20px`}}>
                        9. Installation, Operation, and Rejection of Automatic
                        Personal Information Collection Devices
                      </h2>
                      <p>
                        The Company installs and operates devices or technologies
                        that automatically collect personal information, such as
                        cookies, which continuously store and retrieve member
                        information. Cookies are small text files sent by servers
                        operating the Company's website to members' web browsers
                        and stored on members' computers.
                      </p>
                      <p>The Company uses cookies for the following purposes:</p>
                      <ul>
                        <li>
                          Authentication for goods, content, and payment provided
                          by the Company
                        </li>
                        <li>Notification of usage period for paid services</li>
                        <li>
                          Verification of participation history in events and
                          surveys
                        </li>
                        <li>
                          Provision of differentiated information according to
                          user interests
                        </li>
                        <li>
                          Use as a metric for service improvement through analysis
                          of user patterns
                        </li>
                      </ul>
                      <p>
                        However, members have the choice regarding cookie
                        installation and usage rights, and can allow all cookies,
                        verify each time cookies are stored, or reject all cookie
                        storage through browser options settings according to web
                        browser support.
                      </p>
                      <p>
                        Please note that if you reject cookie storage, services
                        requiring login may be restricted.
                      </p>
  
                      <h2 style={{marginTop: `20px`, marginBottom: `20px`}}>10. Technical / Administrative Protection Measures</h2>
                      <p>
                        The Company implements the following technical and
                        administrative protection measures to prevent loss, theft,
                        leakage, alteration, or damage of members' personal
                        information:
                      </p>
                      <ul>
                        <li>
                          <strong>Access Control and Authority Rights:</strong>
                          The Company limits access rights to personal information
                          to the minimum necessary scope for performing duties.
                          Different authorities are granted to each task manager
                          through individual accounts, and access rights to
                          personal information are changed or revoked when
                          personal information handlers and assigned tasks change.
                          Additionally, when personal information managers and
                          handlers access personal information from outside,
                          secure connection means such as VPN and secure
                          connections are used, and measures are taken on systems
                          and personal PCs for personal information inquiry and
                          processing to prevent unauthorized persons from viewing
                          personal information.
                        </li>
                        <li>
                          <strong>Personal Information Encryption:</strong>
                          The Company encrypts all personal information
                          transmitted/received through information and
                          communication networks or auxiliary storage media, and
                          generally does not store personal information on work
                          PCs. If storage is unavoidable, the data is encrypted
                          for safe storage/processing. Also, in accordance with
                          the Personal Information Protection Act, unique
                          identification numbers (resident registration numbers,
                          foreign registration numbers, etc.), passwords, and
                          sensitive personal information are stored and managed
                          using secure encryption algorithms.
                        </li>
                        <li>
                          <strong>
                            Storage and Prevention of Access Record
                            Forgery/Alteration and Physical Measures:
                          </strong>
                          The Company maintains records of personal information
                          handlers' system processing of personal information for
                          at least 3 months and safely stores these records to
                          prevent forgery, alteration, theft, loss, or deletion.
                          Additionally, physical storage locations containing
                          personal information, such as servers, are designated as
                          places that cannot be arbitrarily accessed, and
                          documents, electronic documents, and auxiliary storage
                          media containing personal information are stored in
                          secure locations protected by locking devices.
                        </li>
                      </ul>
  
                      <h2 style={{marginTop: `20px`, marginBottom: `20px`}}>11. Personal Information Complaint Services</h2>
                      <p>
                        The Company has designated a personal information
                        protection officer as follows to protect members' personal
                        information and handle related complaints. You can contact
                        us through the following contact information for matters
                        related to personal information, such as viewing and
                        correction requests:
                      </p>
                      <ul>
                        <li>
                          <strong>
                            Personal Information Protection Officer:
                          </strong>{" "}
                          Director Lee Seung-seok
                        </li>
                        <li>
                          <strong>Email:</strong> dltmdtjr9683@xrai-api.com
                        </li>
                      </ul>
                      <p>
                        Members can report all personal information
                        protection-related complaints that occur while using the
                        Company's services through the personal information
                        management officer.
                      </p>
  
                      <h2 style={{marginTop: `20px`, marginBottom: `20px`}}>12. Scope of Application of This Privacy Policy</h2>
                      <p>
                        This privacy policy applies to MOHA and related services,
                        which are services of the Company, and different privacy
                        policies may apply to services provided under other
                        brands. Additionally, when personal information is
                        collected on other companies' web/apps linked to MOHA
                        services, the privacy policy of that app/web applies.
                      </p>
  
                      <h2 style={{marginTop: `20px`, marginBottom: `20px`}}>13. Changes to Privacy Policy</h2>
                      <p>
                        In case of additions, deletions, or changes to the privacy
                        policy, we will provide advance notice at least 7 days
                        prior when possible, and in case of significant changes to
                        member rights such as changes to collected personal
                        information items or usage purposes, we will provide
                        notice at least 30 days in advance.
                      </p>
  
                      <h2 style={{marginTop: `20px`, marginBottom: `20px`}}>14. Provisions by Country</h2>
                      <p>
                        These privacy notices may be translated into one or more
                        other languages. This Privacy Notice will take precedence
                        over the English version.
                      </p>
                    </div>
                  </div>
                  )}
                
                </div>
                </div>
                </div>
        </div>
      
    )
}
