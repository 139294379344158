import api from "../apiConfig"


export const apiV1Concepts = async ({page, limit}) => {


    try {

        const resp = await api.get("/api/v1/concepts", {
            page : page,
            limit: limit
        })
        return resp;

    } catch (error) {

        console.log("컨셉가져오기 error", error);
        return error;
    }
}


export const fetchMydata = async ({ queryKey }) => {
    const [_key] = queryKey; // queryKey에서 페이지와 리미트를 가져옴
    try {

      const response = await api.get("/api/v1/mypage/me", {
        // axios는 params로 쿼리 전달
      });

      return response.data; // API 응답 데이터
    } catch (error) {
      console.error("컨셉 가져오기 에러", error);
      throw error; // 오류를 던져 useQuery에서 처리
    }
  };