import styled, { createGlobalStyle } from "styled-components";
import BMJUAOTF from "../../assets/font/BMJUA_otf.otf";
import BMJUATTF from "../../assets/font/BMJUA_ttf.ttf";

export const GlobalStyle = createGlobalStyle`
@font-face {
    font-family: 'BMJUA';
    src: url(${BMJUAOTF}) format('opentype'),
    url(${BMJUATTF}) format('truetype');

    font-weight: normal;
    font-style: normal;
}

/* 기본 폰트 설정 */
@font-face {
    font-family: 'DefaultFont';
    src: local('Arial'), local('sans-serif'); /* 기본 폰트 폴백 */
    unicode-range: U+20, U+24, U+00A5; /* 공백, $, ₩ */
}

/* 폴백 폰트를 body에 적용 */
body {
    font-family: 'BMJUA', 'DefaultFont', sans-serif;
}
`;

export const StyledText = styled.div`
  font-family: "BMJUA", sans-serif !important;
`;

export const StyledTextH5 = styled.h5`
  font-family: "BMJUA", sans-serif !important;
`;
export const StyledTextH4 = styled.h4`
  font-family: "BMJUA", sans-serif !important;
`;
