import styled from "styled-components";

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  margin-bottom: 20px;
`;
export const Th = styled.th`
  border: 1px solid #000;
  padding: 10px;
  text-align: center;
  background-color: #f4f4f4;
`;

export const Td = styled.td`
  border: 1px solid #000;
  padding: 10px;
  /* text-align: center; */
`;

