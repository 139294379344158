import { useURLStore } from "../../store/url/UrlStore";
import LoginHeader from "../home/components/LoginHeader"



export const TermsOfServicePage = () => {
    const languageState = useURLStore((state) => state.language); 

    return (
        <div className="flex flex-col min-h-screen px-4">
        <LoginHeader menu={true}></LoginHeader>

        <div className="container mx-auto py-8 px-4 max-w-6xl">
      <div className="text-2xl font-bold mb-12 text-left">
        {languageState === 'ko' ? (
 <div>
 <div className="">
   <h3 className="">이용약관</h3>
 </div>
 <div className="">
   <h5 className="mb-3"> 제 1조 목적 </h5>
   <p className="mb-3">
      이 약관은 ㈜엑스알에이아이 회사(전자상거래 사업자)가
     운영하는 MOHA 사이버 몰(이하 “몰”이라 한다)에서 제공하는
     인터넷 관련 서비스(이하 “서비스”라 한다)를 이용함에 있어
     사이버 몰과 이용자의 권리․의무 및 책임사항을 규정함을
     목적으로 합니다. 
   </p>
   <p className="mb-3">
     ※「PC통신, 무선 등을 이용하는 전자상거래에 대해서도 그
     성질에 반하지 않는 한 이 약관을 준용합니다.」
   </p>
   <h5 className="mb-3"> 제2조(정의)</h5>
   <p className="mb-0">
       ① “몰”이란 ㈜엑스알에이아이 회사가 재화 또는 용역(이하
     “재화 등”이라 함)을 이용자에게 제공하기 위하여 컴퓨터 등
     정보통신설비를 이용하여 재화 등을 거래할 수 있도록 설정한
     가상의 영업장을 말하며, 아울러 사이버몰을 운영하는
     사업자의 의미로도 사용합니다.
   </p>
   <p className="mb-0">
       ② “이용자”란 “몰”에 접속하여 이 약관에 따라 “몰”이
     제공하는 서비스를 받는 회원 및 비회원을 말합니다.
   </p>
   <p className="mb-0">
       ③ ‘회원’이라 함은 “몰”에 회원등록을 한 자로서,
     계속적으로 “몰”이 제공하는 서비스를 이용할 수 있는 자를
     말합니다.
   </p>
   <p className="mb-0">
       ④ ‘비회원’이라 함은 회원에 가입하지 않고 “몰”이 제공하는
     서비스를 이용하는 자를 말합니다.
   </p>
   <h5 className="mb-3">
      제3조 (약관 등의 명시와 설명 및 개정)
   </h5>
   <p className="mb-0">
       ① “몰”은 이 약관의 내용과 상호 및 대표자 성명, 영업소
     소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를
     포함), 전화번호․모사전송번호․전자우편주소, 사업자등록번호,
     통신판매업 신고번호, 개인정보관리책임자등을 이용자가 쉽게
     알 수 있도록 00 사이버몰의 초기 서비스화면(전면)에
     게시합니다. 다만, 약관의 내용은 이용자가 연결화면을 통하여
     볼 수 있도록 할 수 있습니다.
   </p>
   <p className="mb-0">
       ② “몰은 이용자가 약관에 동의하기에 앞서 약관에 정하여져
     있는 내용 중 청약철회․배송책임․환불조건 등과 같은 중요한
     내용을 이용자가 이해할 수 있도록 별도의 연결화면 또는
     팝업화면 등을 제공하여 이용자의 확인을 구하여야 합니다.
   </p>
   <p className="mb-0">
       ③ “몰”은 「전자상거래 등에서의 소비자보호에 관한 법률」,
     「약관의 규제에 관한 법률」, 「전자문서 및
     전자거래기본법」, 「전자금융거래법」, 「전자서명법」,
     「정보통신망 이용촉진 및 정보보호 등에 관한 법률」,
     「방문판매 등에 관한 법률」, 「소비자기본법」 등 관련 법을
     위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
   </p>
   <p className="mb-0">
       ④ “몰”이 약관을 개정할 경우에는 적용일자 및 개정사유를
     명시하여 현행약관과 함께 몰의 초기화면에 그 적용일자 7일
     이전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게
     불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의
     사전 유예기간을 두고 공지합니다. 이 경우 "몰“은 개정 전
     내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기
     쉽도록 표시합니다.{" "}
   </p>
   <p className="mb-0">
       ⑤ “몰”이 약관을 개정할 경우에는 그 개정약관은 그
     적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미
     체결된 계약에 대해서는 개정 전의 약관조항이 그대로
     적용됩니다. 다만 이미 계약을 체결한 이용자가 개정약관
     조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의
     공지기간 내에 “몰”에 송신하여 “몰”의 동의를 받은 경우에는
     개정약관 조항이 적용됩니다.
   </p>
   <p className="mb-0">
       ⑥ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에
     관하여는 전자상거래 등에서의 소비자보호에 관한 법률,
     약관의 규제 등에 관한 법률, 공정거래위원회가 정하는
     전자상거래 등에서의 소비자 보호지침 및 관계법령 또는
     상관례에 따릅니다.
   </p>
   <h5 className="mb-3"> 제4조(서비스의 제공 및 변경)</h5>
   <p className="mb-0">
       ① “몰”은 다음과 같은 업무를 수행합니다.
   </p>
   <p className="mb-0">
          1. 재화 또는 용역에 대한 정보 제공 및 구매계약의 체결
   </p>
   <p className="mb-0">
          2. 구매계약이 체결된 재화 또는 용역의 배송
   </p>
   <p className="mb-0">     3. 기타 “몰”이 정하는 업무</p>
   <p className="mb-0">
       ② “몰”은 재화 또는 용역의 품절 또는 기술적 사양의 변경
     등의 경우에는 장차 체결되는 계약에 의해 제공할 재화 또는
     용역의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화
     또는 용역의 내용 및 제공일자를 명시하여 현재의 재화 또는
     용역의 내용을 게시한 곳에 즉시 공지합니다.
   </p>
   <p className="mb-0">
       ③ “몰”이 제공하기로 이용자와 계약을 체결한 서비스의
     내용을 재화등의 품절 또는 기술적 사양의 변경 등의 사유로
     변경할 경우에는 그 사유를 이용자에게 통지 가능한 주소로
     즉시 통지합니다.
   </p>
   <p className="mb-0">
       ④ 전항의 경우 “몰”은 이로 인하여 이용자가 입은 손해를
     배상합니다. 다만, “몰”이 고의 또는 과실이 없음을 입증하는
     경우에는 그러하지 아니합니다.
   </p>
   <h5 className="mb-3"> 제5조(서비스의 중단)</h5>
   <p className="mb-0">
       ① “몰”은 컴퓨터 등 정보통신설비의 보수점검․교체 및 고장,
     통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을
     일시적으로 중단할 수 있습니다.
   </p>
   <p className="mb-0">
       ② “몰”은 제1항의 사유로 서비스의 제공이 일시적으로
     중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여
     배상합니다. 단, “몰”이 고의 또는 과실이 없음을 입증하는
     경우에는 그러하지 아니합니다.
   </p>
   <p className="mb-0">
       ③ 사업종목의 전환, 사업의 포기, 업체 간의 통합 등의
     이유로 서비스를 제공할 수 없게 되는 경우에는 “몰”은
     제8조에 정한 방법으로 이용자에게 통지하고 당초 “몰”에서
     제시한 조건에 따라 소비자에게 보상합니다. 다만, “몰”이
     보상기준 등을 고지하지 아니한 경우에는 이용자들의 마일리지
     또는 적립금 등을 “몰”에서 통용되는 통화가치에 상응하는
     현물 또는 현금으로 이용자에게 지급합니다.
   </p>
   <h5 className="mb-3"> 제6조(회원가입)</h5>
   <p className="mb-0">
       ① 이용자는 “몰”이 정한 가입 양식에 따라 회원정보를
     기입한 후 이 약관에 동의한다는 의사표시를 함으로서
     회원가입을 신청합니다.
   </p>
   <p className="mb-0">
       ② “몰”은 제1항과 같이 회원으로 가입할 것을 신청한 이용자
     중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다.
   </p>
   <p className="mb-0">
          1. 가입신청자가 이 약관 제7조제3항에 의하여 이전에
     회원자격을 상실한 적이 있는 경우, 다만 제7조제3항에 의한
     회원자격 상실 후 3년이 경과한 자로서 “몰”의 회원재가입
     승낙을 얻은 경우에는 예외로 한다.
   </p>
   <p className="mb-0">
          2. 등록 내용에 허위, 기재누락, 오기가 있는 경우
   </p>
   <p className="mb-0">
          3. 기타 회원으로 등록하는 것이 “몰”의 기술상 현저히
     지장이 있다고 판단되는 경우
   </p>
   <p className="mb-0">
       ③ 회원가입계약의 성립 시기는 “몰”의 승낙이 회원에게
     도달한 시점으로 합니다.
   </p>
   <p className="mb-0">
       ④ 회원은 회원가입 시 등록한 사항에 변경이 있는 경우,
     상당한 기간 이내에 “몰”에 대하여 회원정보 수정 등의
     방법으로 그 변경사항을 알려야 합니다.
   </p>
   <h5 className="mb-3"> 제7조(회원 탈퇴 및 자격 상실 등)</h5>
   <p className="mb-0">
       ① 회원은 “몰”에 언제든지 탈퇴를 요청할 수 있으며 “몰”은
     즉시 회원탈퇴를 처리합니다.
   </p>
   <p className="mb-0">
       ② 회원이 다음 각 호의 사유에 해당하는 경우, “몰”은
     회원자격을 제한 및 정지시킬 수 있습니다.
   </p>
   <p className="mb-0">
          1. 가입 신청 시에 허위 내용을 등록한 경우
   </p>
   <p className="mb-0">
          2. “몰”을 이용하여 구입한 재화 등의 대금, 기타
     “몰”이용에 관련하여 회원이 부담하는 채무를 기일에 지급하지
     않는 경우
   </p>
   <p className="mb-0">
          3. 다른 사람의 “몰” 이용을 방해하거나 그 정보를
     도용하는 등 전자상거래 질서를 위협하는 경우
   </p>
   <p className="mb-0">
          4. “몰”을 이용하여 법령 또는 이 약관이 금지하거나
     공서양속에 반하는 행위를 하는 경우
   </p>
   <p className="mb-0">
       ③ “몰”이 회원 자격을 제한․정지 시킨 후, 동일한 행위가
     2회 이상 반복되거나 30일 이내에 그 사유가 시정되지
     아니하는 경우 “몰”은 회원자격을 상실시킬 수 있습니다.
   </p>
   <p className="mb-0">
       ④ “몰”이 회원자격을 상실시키는 경우에는 회원등록을
     말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소
     전에 최소한 30일 이상의 기간을 정하여 소명할 기회를
     부여합니다.
   </p>
   <h5 className="mb-3"> 제8조(회원에 대한 통지)</h5>
   <p className="mb-0">
       ① “몰”이 회원에 대한 통지를 하는 경우, 회원이 “몰”과
     미리 약정하여 지정한 전자우편 주소로 할 수 있습니다.
   </p>
   <p className="mb-0">
       ② “몰”은 불특정다수 회원에 대한 통지의 경우 1주일이상
     “몰” 게시판에 게시함으로서 개별 통지에 갈음할 수 있습니다.
     다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는
     사항에 대하여는 개별통지를 합니다.
   </p>
   <h5 className="mb-3">
      제9조(구매신청 및 개인정보 제공 동의 등)
   </h5>
   <p className="mb-0">
       ① “몰”이용자는 “몰”상에서 다음 또는 이와 유사한 방법에
     의하여 구매를 신청하며, “몰”은 이용자가 구매신청을 함에
     있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.
   </p>
   <p className="mb-0">     1. 재화 등의 검색 및 선택</p>
   <p className="mb-0">
           2. 받는 사람의 성명, 주소, 전화번호,
     전자우편주소(또는 이동전화번호) 등의 입력
   </p>
   <p className="mb-0">
           3. 약관내용, 청약철회권이 제한되는 서비스,
     배송료․설치비 등의 비용부담과 관련한 내용에 대한 확인
   </p>
   <p className="mb-0">
           4. 이 약관에 동의하고 위 3.호의 사항을 확인하거나
     거부하는 표시 (예, 마우스 클릭)
   </p>
   <p className="mb-0">
           5. 재화등의 구매신청 및 이에 관한 확인 또는 “몰”의
     확인에 대한 동의
   </p>
   <p className="mb-0">      6. 결제방법의 선택</p>
   <p className="mb-0">
       ② “몰”이 제3자에게 구매자 개인정보를 제공할 필요가 있는
     경우 1) 개인정보를 제공받는 자, 2)개인정보를 제공받는 자의
     개인정보 이용목적, 3) 제공하는 개인정보의 항목, 4)
     개인정보를 제공받는 자의 개인정보 보유 및 이용기간을
     구매자에게 알리고 동의를 받아야 합니다. (동의를 받은
     사항이 변경되는 경우에도 같습니다.)
   </p>
   <p className="mb-0">
       ③ “몰”이 제3자에게 구매자의 개인정보를 취급할 수 있도록
     업무를 위탁하는 경우에는 1) 개인정보 취급위탁을 받는 자,
     2) 개인정보 취급위탁을 하는 업무의 내용을 구매자에게
     알리고 동의를 받아야 합니다. (동의를 받은 사항이 변경되는
     경우에도 같습니다.) 다만, 서비스제공에 관한 계약이행을
     위해 필요하고 구매자의 편의증진과 관련된 경우에는
     「정보통신망 이용촉진 및 정보보호 등에 관한 법률」에서
     정하고 있는 방법으로 개인정보 취급방침을 통해 알림으로써
     고지절차와 동의절차를 거치지 않아도 됩니다.
   </p>
   <h5 className="mb-3"> 제10조 (계약의 성립)</h5>
   <p className="mb-0">
       ① “몰”은 제9조와 같은 구매신청에 대하여 다음 각 호에
     해당하면 승낙하지 않을 수 있습니다. 다만, 미성년자와
     계약을 체결하는 경우에는 법정대리인의 동의를 얻지 못하면
     미성년자 본인 또는 법정대리인이 계약을 취소할 수 있다는
     내용을 고지하여야 합니다.
   </p>
   <p className="mb-0">
           1. 신청 내용에 허위, 기재누락, 오기가 있는 경우
   </p>
   <p className="mb-0">
           2. 미성년자가 담배, 주류 등 청소년보호법에서
     금지하는 재화 및 용역을 구매하는 경우
   </p>
   <p className="mb-0">
           3. 기타 구매신청에 승낙하는 것이 “몰” 기술상 현저히
     지장이 있다고 판단하는 경우
   </p>
   <p className="mb-0">
           4. 실명이 아니거나 타인의 명의를 이용하여 구매신청을
     진행한 경우
   </p>
   <p className="mb-0">
           5. 부정한 용도로 본 서비스를 이용하고자 하는 경우
   </p>{" "}
   <p className="mb-0">
           6. 기타 규정한 제반사항을 위반하면 구매신청을 하는
     경우
   </p>
   <p className="mb-0">
       ② “몰”의 승낙이 제12조제1항의 수신확인통지형태로
     이용자에게 도달한 시점에 계약이 성립한 것으로 봅니다.
   </p>
   <p className="mb-0">
       ③ “몰”의 승낙의 의사표시에는 이용자의 구매 신청에 대한
     확인 및 판매가능 여부, 구매신청의 정정 취소 등에 관한 정보
     등을 포함하여야 합니다.
   </p>
   <p className="mb-0">
       ④ “몰”은 구매 및 서비스 이용신청이 다음 각 호에 해당
     하는 경우 그 신청에 대하여 승낙 제한 사유가 해소될 때까지
     승낙을 유보하거나 거절할 수 있습니다.
   </p>
   <p className="mb-0">
           1. “몰”의 기술상 지장이 있는 경우
   </p>
   <p className="mb-0">
           2. “몰”의 귀책사유로 이용승낙이 곤란한 경우
   </p>
   <h5 className="mb-3"> 제11조(지급방법)</h5>
   <p className="mb-0">
       “몰”에서 구매한 재화 또는 용역에 대한 대금지급방법은
     다음 각 호의 방법중 가용한 방법으로 할 수 있습니다. 단,
     “몰”은 이용자의 지급방법에 대하여 재화 등의 대금에 어떠한
     명목의 수수료도 추가하여 징수할 수 없습니다.
   </p>
   <p className="mb-0">
           1. 폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체
   </p>
   <p className="mb-0">
           2. 선불카드, 직불카드, 신용카드 등의 각종 카드 결제
   </p>
   <p className="mb-0">      3. 온라인무통장입금</p>
   <p className="mb-0">      4. 전자화폐에 의한 결제</p>
   <p className="mb-0">      5. 수령 시 대금지급</p>
   <p className="mb-0">
           6. 마일리지 등 “몰”이 지급한 포인트에 의한 결제
   </p>
   <p className="mb-0">
           7. “몰”과 계약을 맺었거나 “몰”이 인정한 상품권에
     의한 결제
   </p>
   <p className="mb-0">
           8. 기타 전자적 지급 방법에 의한 대금 지급 등
   </p>
   <h5 className="mb-3">
      제12조(수신확인통지․구매신청 변경 및 취소)
   </h5>
   <p className="mb-0">
       ① “몰”은 이용자의 구매신청이 있는 경우 이용자에게
     수신확인통지를 합니다.
   </p>
   <p className="mb-0">
       ② 수신확인통지를 받은 이용자는 의사표시의 불일치 등이
     있는 경우에는 수신확인통지를 받은 후 즉시 구매신청 변경 및
     취소를 요청할 수 있고 “몰”은 배송 전에 이용자의 요청이
     있는 경우에는 지체 없이 그 요청에 따라 처리하여야 합니다.
     다만 이미 대금을 지불한 경우에는 제15조의 청약철회 등에
     관한 규정에 따릅니다.
   </p>
   <h5 className="mb-3"> 제13조(재화 등의 공급)</h5>
   <p className="mb-0">
       ① “몰”은 이용자와 재화 등의 공급시기에 관하여 별도의
     약정이 없는 이상, 이용자가 청약을 한 날부터 7일 이내에
     재화 등을 배송할 수 있도록 주문제작, 포장 등 기타의 필요한
     조치를 취합니다. 다만, “몰”이 이미 재화 등의 대금의 전부
     또는 일부를 받은 경우에는 대금의 전부 또는 일부를 받은
     날부터 3영업일 이내에 조치를 취합니다. 이때 “몰”은
     이용자가 재화 등의 공급 절차 및 진행 사항을 확인할 수
     있도록 적절한 조치를 합니다.
   </p>
   <p className="mb-0">
       ② “몰”은 이용자가 구매한 재화에 대해 배송수단, 수단별
     배송비용 부담자, 수단별 배송기간 등을 명시합니다. 만약
     “몰”이 약정 배송기간을 초과한 경우에는 그로 인한 이용자의
     손해를 배상하여야 합니다. 다만 “몰”이 고의․과실이 없음을
     입증한 경우에는 그러하지 아니합니다.
   </p>
   <h5 className="mb-3"> 제14조(환급)</h5>
   <p className="mb-0">
       “몰”은 이용자가 구매신청한 재화 등이 품절 등의 사유로
     인도 또는 제공을 할 수 없을 때에는 지체 없이 그 사유를
     이용자에게 통지하고 사전에 재화 등의 대금을 받은 경우에는
     대금을 받은 날부터 3영업일 이내에 환급하거나 환급에 필요한
     조치를 취합니다.
   </p>
   <h5 className="mb-3"> 제15조(청약철회 등)</h5>
   <p className="mb-0">
       ① “몰”과 재화등의 구매에 관한 계약을 체결한 이용자는
     「전자상거래 등에서의 소비자보호에 관한 법률」 제13조
     제2항에 따른 계약내용에 관한 서면을 받은 날(그 서면을 받은
     때보다 재화 등의 공급이 늦게 이루어진 경우에는 재화 등을
     공급받거나 재화 등의 공급이 시작된 날을 말합니다)부터 7일
     이내에는 청약의 철회를 할 수 있습니다. 다만, 청약철회에
     관하여 「전자상거래 등에서의 소비자보호에 관한 법률」에
     달리 정함이 있는 경우에는 동 법 규정에 따릅니다.
   </p>
   <p className="mb-0">
       ② 이용자는 재화 등을 배송 받은 경우 다음 각 호의 1에
     해당하는 경우에는 반품 및 교환을 할 수 없습니다.
   </p>
   <p className="mb-0">
           1. 이용자에게 책임 있는 사유로 재화 등이 멸실 또는
     훼손된 경우(다만, 재화 등의 내용을 확인하기 위하여 포장
     등을 훼손한 경우에는 청약철회를 할 수 있습니다)
   </p>
   <p className="mb-0">
           2. 이용자의 사용 또는 일부 소비에 의하여 재화 등의
     가치가 현저히 감소한 경우
   </p>
   <p className="mb-0">
           3. 시간의 경과에 의하여 재판매가 곤란할 정도로
     재화등의 가치가 현저히 감소한 경우
   </p>
   <p className="mb-0">
           4. 같은 성능을 지닌 재화 등으로 복제가 가능한 경우
     그 원본인 재화 등의 포장을 훼손한 경우
   </p>
   <p className="mb-0">
       ③ 제2항제2호 내지 제4호의 경우에 “몰”이 사전에 청약철회
     등이 제한되는 사실을 소비자가 쉽게 알 수 있는 곳에
     명기하거나 시용상품을 제공하는 등의 조치를 하지 않았다면
     이용자의 청약철회 등이 제한되지 않습니다.
   </p>
   <p className="mb-0">
       ④ 이용자는 제1항 및 제2항의 규정에 불구하고 재화 등의
     내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행된
     때에는 당해 재화 등을 공급받은 날부터 3월 이내, 그 사실을
     안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회 등을
     할 수 있습니다.
   </p>
   <h5 className="mb-3"> 제16조(청약철회 등의 효과)</h5>
   <p className="mb-0">
       ① “몰”은 이용자로부터 재화 등을 반환받은 경우 3영업일
     이내에 이미 지급받은 재화 등의 대금을 환급합니다. 이 경우
     “몰”이 이용자에게 재화등의 환급을 지연한때에는 그
     지연기간에 대하여 「전자상거래 등에서의 소비자보호에 관한
     법률 시행령」제21조의2에서 정하는 지연이자율을 곱하여
     산정한 지연이자를 지급합니다.
   </p>
   <p className="mb-0">
       ② “몰”은 위 대금을 환급함에 있어서 이용자가 신용카드
     또는 전자화폐 등의 결제수단으로 재화 등의 대금을 지급한
     때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금
     재화 등의 대금의 청구를 정지 또는 취소하도록 요청합니다.
   </p>
   <p className="mb-0">
       ③ 청약철회 등의 경우 공급받은 재화 등의 반환에 필요한
     비용은 이용자가 부담합니다. “몰”은 이용자에게 청약철회
     등을 이유로 위약금 또는 손해배상을 청구하지 않습니다. 다만
     재화 등의 내용이 표시·광고 내용과 다르거나 계약내용과
     다르게 이행되어 청약철회 등을 하는 경우 재화 등의 반환에
     필요한 비용은 “몰”이 부담합니다.
   </p>
   <p className="mb-0">
       ④ 이용자가 재화 등을 제공받을 때 발송비를 부담한 경우에
     “몰”은 청약철회 시 그 비용을 누가 부담하는지를 이용자가
     알기 쉽도록 명확하게 표시합니다.
   </p>
   <h5 className="mb-3"> 제17조(개인정보보호)</h5>
   <p className="mb-0">
       ① “몰”은 이용자의 개인정보 수집시 서비스제공을 위하여
     필요한 범위에서 최소한의 개인정보를 수집합니다. 다음
     사항을 필수사항으로 수집하며, 그 외 사항은 선택사항으로
     합니다.
   </p>
   <p className="mb-0">      1. 성명</p>
   <p className="mb-0">
           2. 주민등록번호(회원의 경우) 또는 외국인 등록번호
   </p>
   <p className="mb-0">      3. 주소</p>
   <p className="mb-0">      4. 전화번호</p>
   <p className="mb-0">      5. ID(회원의 경우)</p>
   <p className="mb-0">      6. 비밀번호(회원의 경우)</p>
   <p className="mb-0">      7. 전자우편주소</p>
   <p className="mb-0">
       ② “몰”은 이용자의 개인식별이 가능한 개인정보를 수집할
     경우 반드시 당해 이용자의 동의를 받습니다.
   </p>
   <p className="mb-0">
       ③ “몰”은 이용자가 제공한 개인정보를 동의 없이 목적이외의
     이용이나 제3자에게 제공할 수 없으며, 이에 대한 책임은
     “몰”이집니다. 다만 다음 경우에 대해 예외로 합니다.
   </p>
   <p className="mb-0">
           1. 배송업무상 배송업체에게 배송에 필요한 최소한의
     이용자 정보(성명, 주소, 전화번호)를 알려주는 경우
   </p>
   <p className="mb-0">
           2. 회원관리, 시스템관리, 정보의 제공 등을 위하여
     “몰”이 호스팅을 받는 업체에 제공하는 경우
   </p>
   <p className="mb-0">
           3. 통계작성, 학술연구 또는 시장조사를 위하여 필요한
     경우에 특정 개인을 식별할 수 없는 형태로 제공하는 경우
   </p>
   <p className="mb-0">
           4. 재화등 거래에 따른 대금정산을 위하여 필요한 경우
   </p>
   <p className="mb-0">
           5. 도용, 사칭 등을 방지하기 위한 본인확인이 필요한
     경우
   </p>
   <p className="mb-0">
           6. 법률의 규정 또는 법률에 의하여 필요한 불가피한
     사유가 있는 경우
   </p>
   <p className="mb-0">
       ④ “몰”이 제2항과 제3항에 의해 이용자의 동의를 받아야
     하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및
     전화번호, 기타 연락처), 정보의 수집목적 및 이용목적,
     제3자에 대한 정보제공 관련사항(제공받은자, 제공목적 및
     제공할 정보의 내용) 등 「정보통신망 이용촉진 및 정보보호
     등에 관한 법률」 제22조제2항이 규정한 사항을 미리
     명시하거나 고지해야 하며 이용자는 언제든지 이 동의를
     철회할 수 있습니다.
   </p>
   <p className="mb-0">
       ⑤ 이용자는 언제든지 “몰”이 가지고 있는 자신의 개인정보에
     대해 열람 및 오류정정을 요구할 수 있으며 “몰”은 이에 대해
     지체 없이 필요한 조치를 취할 의무를 집니다. 이용자가
     오류의 정정을 요구한 경우에는 “몰”은 그 오류를 정정할
     때까지 당해 개인정보를 이용하지 않습니다.
   </p>
   <p className="mb-0">
       ⑥ “몰”은 개인정보 보호를 위하여 이용자의 개인정보를
     취급하는 자를 최소한으로 제한하여야 하며 신용카드,
     은행계좌 등을 포함한 이용자의 개인정보의 분실, 도난, 유출,
     동의 없는 제3자 제공, 변조 등으로 인한 이용자의 손해에
     대하여 모든 책임을 집니다.
   </p>
   <p className="mb-0">
       ⑦ “몰” 또는 그로부터 개인정보를 제공받은 제3자는
     개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는
     당해 개인정보를 지체 없이 파기합니다.
   </p>
   <p className="mb-0">
           1. 회원가입 정보 : 회원탈퇴, 회원제명이 된 경우
   </p>
   <p className="mb-0">
           2. 대금지급 정보 : 대금의 완제일 또는
     채권소멸시효기간이 만료된 때
   </p>
   <p className="mb-0">
           3. 배송 정보 : 물품 또는 서비스가 인도되거나 제공된
     때
   </p>
   <p className="mb-0">
           4. 설문조사, 이벤트 등의 목적을 위하여 수집된 경우 :
     당해 설문조사, 이벤트 종료 시 다만, 수집목적 또는 제공받은
     목적이 달성된 경우에도 상법 등 관계 법령의 규정에 의하여
     보존할 필요성이 있는 경우에는 귀하의 개인정보를 보유할 수
     있습니다.
   </p>
   <p className="mb-0">
                1). 계약 또는 청약철회 등에 관한 기록 : 5년
   </p>
   <p className="mb-0">
                2). 대금결제 및 재화등의 공급에 관한 기록 : 5년
   </p>
   <p className="mb-0">
                3). 소비자의 불만 또는 분쟁처리에 관한 기록 :
     3년
   </p>
   <p className="mb-0">
       ⑧ “몰”은 개인정보의 수집·이용·제공에 관한 동의 란을 미리
     선택한 것으로 설정해두지 않습니다. 또한 개인정보의
     수집·이용·제공에 관한 이용자의 동의거절시 제한되는
     서비스를 구체적으로 명시하고, 필수수집항목이 아닌
     개인정보의 수집·이용·제공에 관한 이용자의 동의 거절을
     이유로 회원가입 등 서비스 제공을 제한하거나 거절하지
     않습니다.
   </p>
   <h5 className="mb-3"> 제17조(개인정보보호)</h5>
   <p className="mb-0">
       ① “몰”은 법령과 이 약관이 금지하거나 공서양속에 반하는
     행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고,
     안정적으로 재화․용역을 제공하는데 최선을 다하여야 합니다.
   </p>
   <p className="mb-0">
       ② “몰”은 이용자가 안전하게 인터넷 서비스를 이용할 수
     있도록 이용자의 개인정보(신용정보 포함)보호를 위한 보안
     시스템을 갖추어야 합니다.
   </p>
   <p className="mb-0">
       ③ “몰”이 상품이나 용역에 대하여 「표시․광고의 공정화에
     관한 법률」 제3조 소정의 부당한 표시․광고행위를 함으로써
     이용자가 손해를 입은 때에는 이를 배상할 책임을 집니다.
   </p>
   <p className="mb-0">
       ④ “몰”은 이용자가 원하지 않는 영리목적의 광고성
     전자우편을 발송하지 않습니다.
   </p>
   <h5 className="mb-3">
      제19조(회원의 ID 및 비밀번호에 대한 의무)
   </h5>
   <p className="mb-0">
       ① 제17조의 경우를 제외한 ID와 비밀번호에 관한 관리책임은
     회원에게 있습니다.
   </p>
   <p className="mb-0">
       ② 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게
     해서는 안됩니다.
   </p>
   <p className="mb-0">
       ③ 회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가
     사용하고 있음을 인지한 경우에는 바로 “몰”에 통보하고
     “몰”의 안내가 있는 경우에는 그에 따라야 합니다.
   </p>
   <h5 className="mb-3">
      제20조(이용자의 의무) 이용자는 다음 행위를 하여서는 안
     됩니다.
   </h5>
   <p className="mb-0">
           1. 신청 또는 변경시 허위 내용의 등록
   </p>
   <p className="mb-0">      2. 타인의 정보 도용</p>
   <p className="mb-0">      3. “몰”에 게시된 정보의 변경</p>
   <p className="mb-0">
           4. “몰”이 정한 정보 이외의 정보(컴퓨터 프로그램 등)
     등의 송신 또는 게시
   </p>
   <p className="mb-0">
           5. “몰” 기타 제3자의 저작권 등 지적재산권에 대한
     침해
   </p>
   <p className="mb-0">
           6. “몰” 기타 제3자의 명예를 손상시키거나 업무를
     방해하는 행위
   </p>
   <p className="mb-0">
           7. 외설 또는 폭력적인 메시지, 화상, 음성, 기타
     공서양속에 반하는 정보를 몰에 공개 또는 게시하는 행위
   </p>
   <h5 className="mb-3">
      제21조(연결“몰”과 피연결“몰” 간의 관계)
   </h5>
   <p className="mb-0">
       ① 상위 “몰”과 하위 “몰”이 하이퍼링크(예: 하이퍼링크의
     대상에는 문자, 그림 및 동화상 등이 포함됨)방식 등으로
     연결된 경우, 전자를 연결 “몰”(웹 사이트)이라고 하고 후자를
     피연결 “몰”(웹사이트)이라고 합니다.
   </p>
   <p className="mb-0">
       ② 연결“몰”은 피연결“몰”이 독자적으로 제공하는 재화 등에
     의하여 이용자와 행하는 거래에 대해서 보증 책임을 지지
     않는다는 뜻을 연결“몰”의 초기화면 또는 연결되는 시점의
     팝업화면으로 명시한 경우에는 그 거래에 대한 보증 책임을
     지지 않습니다.
   </p>
   <h5 className="mb-3"> 제22조(저작권의 귀속 및 이용제한)</h5>
   <p className="mb-0">
       ① “몰“이 작성한 저작물에 대한 저작권 기타 지적재산권은
     ”몰“에 귀속합니다.
   </p>
   <p className="mb-0">
       ② 이용자는 “몰”을 이용함으로써 얻은 정보 중 “몰”에게
     지적재산권이 귀속된 정보를 “몰”의 사전 승낙 없이 복제,
     송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로
     이용하거나 제3자에게 이용하게 하여서는 안됩니다.
   </p>
   <p className="mb-0">
       ③ “몰”은 약정에 따라 이용자에게 귀속된 저작권을 사용하는
     경우 당해 이용자에게 통보하여야 합니다.
   </p>
   <h5 className="mb-3"> 제23조(분쟁해결)</h5>
   <p className="mb-0">
       ① “몰”은 이용자가 제기하는 정당한 의견이나 불만을
     반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를
     설치․운영합니다.
   </p>
   <p className="mb-0">
       ② “몰”은 이용자로부터 제출되는 불만사항 및 의견은
     우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가
     곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시
     통보해 드립니다.
   </p>
   <p className="mb-0">
       ③ “몰”과 이용자 간에 발생한 전자상거래 분쟁과 관련하여
     이용자의 피해구제신청이 있는 경우에는 공정거래위원회 또는
     시·도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수
     있습니다.
   </p>
   <h5 className="mb-3"> 제24조(면책조항)</h5>
   <p className="mb-0">
       ① “몰”은 천재지변, 전쟁 및 기타 이에 준하는 불가항력으로
     인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 대한
     책임이 면제됩니다.
   </p>
   <p className="mb-0">
       ② “몰”은 기간통신 사업자가 전기통신 서비스를 중지하거나
     정상적으로 제공하지 아니하여 손해가 발생한 경우 책임이
     면제됩니다.
   </p>
   <p className="mb-0">
       ③ “몰”은 서비스용 설비의 보수, 교체, 정기점검, 공사 등
     부득이한 사유로 발생한 손해에 대한 책임이 면제됩니다.
   </p>
   <p className="mb-0">
       ④ “몰”은 이용자의 귀책사유로 인한 서비스 이용의 장애
     또는 손해에 대하여 책임을지지 않습니다.
   </p>
   <p className="mb-0">
       ⑤ “몰”은 이용자의 컴퓨터, 디바이스 등 오류에 의해 손해가
     발생한 경우, 또는 회원이 신상정보 및 전자우편 주소를
     부실하게 기재하여 손해가 발생한 경우 책임을 지지 않습니다.
   </p>
   <p className="mb-0">
       ⑥ “몰”은 이용자가 서비스를 이용하며 기대하는 수익을 얻지
     못하거나 상실한 것에 대하여 책임을지지 않습니다.
   </p>
   <p className="mb-0">
       ⑦ “몰”은 이용자가 서비스를 이용하면서 얻은 자료로 인한
     손해에 대하여 책임을지지 않습니다. 또한 “몰”은 이용자가
     서비스를 이용하여 타 이용자로 인해 입게 되는 정신적 피해에
     대하여 보상할 책임을지지 않습니다.
   </p>
   <p className="mb-0">
       ⑧ “몰”은 이용자 상호간 및 이용자와 제3자 상호 간에
     서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며,
     이로 인한 손해를 배상할 책임도 없습니다.
   </p>
   <h5 className="mb-3"> 제25조(재판권 및 준거법)</h5>
   <p className="mb-0">
       ① “몰”과 이용자 간에 발생한 전자상거래 분쟁에 관한
     소송은 제소 당시의 이용자의 주소에 의하고, 주소가 없는
     경우에는 거소를 관할하는 지방법원의 전속관할로 합니다.
     다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나
     외국 거주자의 경우에는 민사소송법상의 관할법원에
     제기합니다.
   </p>
   <p className="mb-0">
       ② “몰”과 이용자 간에 제기된 전자상거래 소송에는 한국법을
     적용합니다.
   </p>
   <p className="mb-0">
       ③ 본 약관에 명시되지 않은 사항은 전기통신사업법 등
     관계법령과 상관습에 따릅니다.
   </p>
 </div>
</div>
        ) : (
<div>
                  <h1>MOHA Terms of Service</h1>

                  <h2 style={{marginTop: `20px`, marginBottom: `20px`}}>1. Purpose</h2>
                  <p>
                    These terms and conditions establish the rights,
                    obligations, and responsibilities between the cyber mall
                    (hereinafter referred to as the "Mall") operated by XRAI
                    Co., Ltd. (e-commerce business operator) and users regarding
                    the use of internet-related services (hereinafter referred
                    to as "Services") provided by the Mall.
                    <br />※ These terms and conditions also apply to e-commerce
                    using PC communications, wireless communications, etc.,
                    unless contrary to their nature.
                  </p>
                  <h2 style={{marginTop: `20px`, marginBottom: `20px`}}>2. Definitions</h2>
                  <ul>
                    <li>
                      "Mall" refers to a virtual business establishment set up
                      by XRAI Co., Ltd. using information and communications
                      equipment such as computers to provide goods or services
                      (hereinafter referred to as "goods, etc.") to users, and
                      also refers to the cyber mall operator.
                    </li>
                    <li>
                      "User" refers to members and non-members who access the
                      "Mall" and receive services provided by the "Mall" in
                      accordance with these terms and conditions.
                    </li>
                    <li>
                      'Member' refers to a person who has registered with the
                      "Mall" and can continuously use the services provided by
                      the "Mall".
                    </li>
                    <li>
                      'Non-member' refers to a person who uses the services
                      provided by the "Mall" without registering for membership.
                    </li>
                  </ul>

                  <h2 style={{marginTop: `20px`, marginBottom: `20px`}}>
                    3. Display, Explanation, and Revision of Terms and
                    Conditions
                  </h2>
                  <ol>
                    <li>
                      The "Mall" shall post these terms and conditions, company
                      name, representative's name, business location address
                      (including address for handling consumer complaints),
                      phone number, fax number, email address, business
                      registration number, telecommunications sales business
                      report number, personal information manager, etc., where
                      users can easily see them on the initial service screen of
                      the cyber mall. However, the content of the terms and
                      conditions can be made available to users through a linked
                      screen.
                    </li>
                    <li>
                      Before users agree to the terms and conditions, the "Mall"
                      must obtain user confirmation through a separate linked
                      screen or pop-up screen for important contents such as
                      withdrawal of subscription, delivery responsibility,
                      refund conditions, etc., specified in the terms and
                      conditions.
                    </li>
                    <li>
                      The "Mall" may revise these terms and conditions within
                      the scope that does not violate related laws such as the
                      "Act on Consumer Protection in Electronic Commerce," "Act
                      on Regulation of Terms and Conditions," "Framework Act on
                      Electronic Documents and Electronic Commerce," "Electronic
                      Financial Transactions Act," "Electronic Signature Act,"
                      "Act on Promotion of Information and Communications
                      Network Utilization and Information Protection,"
                      "Door-to-Door Sales Act," and "Framework Act on
                      Consumers."
                    </li>
                    <li>
                      When revising the terms and conditions, the "Mall" shall
                      specify the application date and reasons for revision and
                      post them along with the current terms and conditions on
                      the Mall's initial screen from 7 days before the
                      application date until the day before application.
                      However, when changing terms and conditions unfavorably to
                      users, it shall give at least 30 days advance notice
                      period. In this case, the "Mall" shall clearly compare and
                      display the pre-revision and post-revision contents for
                      users to understand easily.
                    </li>
                    <li>
                      When the "Mall" revises the terms and conditions, the
                      revised terms and conditions apply only to contracts
                      concluded after the application date, and the previous
                      terms and conditions apply to contracts already concluded
                      before that date. However, if a user who has already
                      concluded a contract sends their intention to be subject
                      to the revised terms and conditions to the "Mall" within
                      the notice period of the revised terms and conditions
                      under Paragraph 3 and receives the "Mall's" consent, the
                      revised terms and conditions shall apply.
                    </li>
                    <li>
                      Matters not specified in these terms and conditions and
                      the interpretation of these terms and conditions shall be
                      governed by the Act on Consumer Protection in Electronic
                      Commerce, the Act on Regulation of Terms and Conditions,
                      the Consumer Protection Guidelines in Electronic Commerce
                      established by the Fair Trade Commission, and related laws
                      or commercial practices.
                    </li>
                  </ol>

                  <h2 style={{marginTop: `20px`, marginBottom: `20px`}}>4. Provision and Change of Services</h2>
                  <ol>
                    <li>
                      The "Mall" performs the following tasks:
                      <ul>
                        <li>
                          Provision of information about goods or services and
                          conclusion of purchase contracts
                        </li>
                        <li>
                          Delivery of goods or services for which purchase
                          contracts have been concluded
                        </li>
                        <li>Other tasks determined by the "Mall"</li>
                      </ul>
                    </li>
                    <li>
                      The "Mall" may change the contents of goods or services to
                      be provided by future contracts in cases of sold-out goods
                      or services or changes in technical specifications. In
                      this case, the changed contents and provision date of the
                      goods or services shall be immediately posted where the
                      current contents of the goods or services are posted.
                    </li>
                    <li>
                      If the "Mall" needs to change the contents of services
                      contracted with users due to sold-out goods or services or
                      changes in technical specifications, it shall immediately
                      notify the reason to an address where notification is
                      possible.
                    </li>
                    <li>
                      In the case of the preceding paragraph, the "Mall" shall
                      compensate for damages suffered by users. However, this
                      shall not apply if the "Mall" proves that there was no
                      intention or negligence.
                    </li>
                  </ol>

                  <h2 style={{marginTop: `20px`, marginBottom: `20px`}}>5. Suspension of Service</h2>
                  <ol>
                    <li>
                      The "Mall" may temporarily suspend service provision in
                      cases of maintenance inspection, replacement and failure
                      of information and communication facilities, interruption
                      of communications, etc.
                    </li>
                    <li>
                      The "Mall" shall compensate for damages suffered by users
                      or third parties due to temporary suspension of service
                      provision for reasons in Paragraph 1. However, this shall
                      not apply if the "Mall" proves that there was no intention
                      or negligence.
                    </li>
                    <li>
                      If services cannot be provided due to business conversion,
                      business abandonment, merger between companies, etc., the
                      "Mall" shall notify users in the method specified in
                      Article 8 and compensate consumers according to the
                      conditions initially presented by the "Mall". However, if
                      the "Mall" has not notified compensation standards, etc.,
                      mileage or accumulated points of users shall be paid to
                      users in kind or cash corresponding to the currency value
                      used in the "Mall".
                    </li>
                  </ol>

                  <h2 style={{marginTop: `20px`, marginBottom: `20px`}}>6. Membership Registration</h2>
                  <ol>
                    <li>
                      Users apply for membership registration by filling in
                      member information according to the registration form set
                      by the "Mall" and expressing their intention to agree to
                      these terms and conditions.
                    </li>
                    <li>
                      The "Mall" shall register as members those who apply for
                      membership as in Paragraph 1 unless they fall under any of
                      the following:
                      <ul>
                        <li>
                          Case where the applicant has previously lost
                          membership qualification under Article 7, Paragraph 3,
                          except when 3 years have passed since the loss of
                          membership qualification and approval for
                          re-registration has been obtained from the "Mall"
                        </li>
                        <li>
                          Case where there are false, omissions, or errors in
                          registration details
                        </li>
                        <li>
                          Case where registration as a member is deemed
                          technically significantly hindering to the "Mall"
                        </li>
                      </ul>
                    </li>
                    <li>
                      The time of establishment of membership registration
                      contract shall be when the "Mall's" approval reaches the
                      member.
                    </li>
                    <li>
                      In case of changes to matters registered at the time of
                      membership registration, members shall notify the "Mall"
                      of such changes through member information modification
                      within a reasonable period.
                    </li>
                  </ol>

                  <h2 style={{marginTop: `20px`, marginBottom: `20px`}}>
                    7. Withdrawal from Membership and Loss of Qualification
                  </h2>
                  <ol>
                    <li>
                      Members may request withdrawal from the "Mall" at any
                      time, and the "Mall" shall immediately process the
                      membership withdrawal.
                    </li>
                    <li>
                      The "Mall" may restrict or suspend membership
                      qualification if members fall under any of the following
                      cases:
                      <ul>
                        <li>
                          Case where false information was registered when
                          applying for registration
                        </li>
                        <li>
                          Case where payment obligations such as payment for
                          goods purchased using the "Mall" or other obligations
                          related to using the "Mall" are not fulfilled by the
                          due date
                        </li>
                        <li>
                          Case where the use of the "Mall" by others is
                          interfered with or their information is stolen,
                          threatening the order of electronic commerce
                        </li>
                        <li>
                          Case where acts prohibited by laws or these terms and
                          conditions are performed or acts contrary to public
                          order and morals are performed using the "Mall"
                        </li>
                      </ul>
                    </li>
                    <li>
                      After restricting or suspending membership qualification,
                      if the same act is repeated twice or more or the reason is
                      not corrected within 30 days, the "Mall" may revoke
                      membership qualification.
                    </li>
                    <li>
                      When the "Mall" revokes membership qualification, it shall
                      delete the member registration. In this case, it shall
                      notify the member and give at least 30 days to explain
                      before deleting the member registration.
                    </li>
                  </ol>

                  <h2 style={{marginTop: `20px`, marginBottom: `20px`}}>8. Notification to Members</h2>
                  <ol>
                    <li>
                      When the "Mall" notifies members, it can do so through the
                      email address previously agreed upon and designated by the
                      member.
                    </li>
                    <li>
                      For notifications to unspecified multiple members, the
                      "Mall" may substitute individual notifications by posting
                      on the "Mall's" bulletin board for at least one week.
                      However, individual notifications shall be made for
                      matters that significantly affect the member's own
                      transactions.
                    </li>
                  </ol>
                  <h2 style={{marginTop: `20px`, marginBottom: `20px`}}>
                    9. Purchase Request and Consent to Provide Personal
                    Information
                  </h2>
                  <ol>
                    <li>
                      Users of the "Mall" shall request purchases through the
                      following or similar methods, and the "Mall" shall provide
                      the following information in an easily understandable
                      manner when users make purchase requests:
                      <ol>
                        <li>Search and selection of goods</li>
                        <li>
                          Input of recipient's name, address, phone number,
                          email address (or mobile phone number)
                        </li>
                        <li>
                          Confirmation of terms and conditions, services with
                          restricted right of withdrawal, and costs such as
                          shipping fees and installation fees
                        </li>
                        <li>
                          Indication of agreement to these terms and conditions
                          and confirmation or rejection of items in point 3
                          (e.g., mouse click)
                        </li>
                        <li>
                          Purchase request for goods and confirmation thereof,
                          or consent to the "Mall's" confirmation
                        </li>
                        <li>Selection of payment method</li>
                      </ol>
                    </li>
                    <li>
                      When the "Mall" needs to provide buyer's personal
                      information to third parties, it must inform and obtain
                      consent from the buyer regarding:
                      <ol>
                        <li>The recipient of personal information</li>
                        <li>
                          The purpose of use of personal information by the
                          recipient
                        </li>
                        <li>Items of personal information to be provided</li>
                        <li>
                          The recipient's period of retention and use of
                          personal information
                        </li>
                      </ol>
                      (This also applies when there are changes to matters for
                      which consent was obtained.)
                    </li>
                    <li>
                      When the "Mall" outsources the handling of buyer's
                      personal information to third parties, it must inform and
                      obtain consent from the buyer regarding:
                      <ol>
                        <li>
                          The person receiving the outsourcing of personal
                          information handling
                        </li>
                        <li>
                          The content of the outsourced personal information
                          handling work
                        </li>
                      </ol>
                      (This also applies when there are changes to matters for
                      which consent was obtained.) However, when it is necessary
                      for the fulfillment of service provision contracts and
                      related to improving buyer convenience, notification
                      through privacy policy as prescribed by the "Act on
                      Promotion of Information and Communications Network
                      Utilization and Information Protection" may substitute for
                      notification and consent procedures.
                    </li>
                  </ol>

                  <h2 style={{marginTop: `20px`, marginBottom: `20px`}}>10. Formation of Contract</h2>
                  <ol>
                    <li>
                      The "Mall" may not accept purchase requests as in Article
                      9 if they fall under any of the following cases. However,
                      when concluding contracts with minors, it must notify that
                      the minor or their legal representative can cancel the
                      contract if consent from the legal representative is not
                      obtained:
                      <ol>
                        <li>
                          Case where there are false statements, omissions, or
                          errors in the application
                        </li>
                        <li>
                          Case where a minor purchases goods and services
                          prohibited by the Youth Protection Act such as tobacco
                          and alcohol
                        </li>
                        <li>
                          Case where acceptance of the purchase request is
                          deemed technically significantly hindering to the
                          "Mall"
                        </li>
                        <li>
                          Case where the purchase request is made using a false
                          name or another person's name
                        </li>
                        <li>
                          Case where the service is intended to be used for
                          improper purposes
                        </li>
                        <li>
                          Case where the purchase request violates other
                          prescribed general matters
                        </li>
                      </ol>
                    </li>
                    <li>
                      The contract shall be deemed formed when the "Mall's"
                      acceptance reaches the user in the form of receipt
                      confirmation notice under Article 12, Paragraph 1.
                    </li>
                    <li>
                      The "Mall's" expression of acceptance shall include
                      information about confirmation of user's purchase request,
                      availability for sale, correction and cancellation of
                      purchase request, etc.
                    </li>
                    <li>
                      The "Mall" may withhold or reject acceptance of purchase
                      and service use requests until acceptance restriction
                      reasons are resolved in the following cases:
                      <ol>
                        <li>
                          When there are technical difficulties with the "Mall"
                        </li>
                        <li>
                          When acceptance of use is difficult due to reasons
                          attributable to the "Mall"
                        </li>
                      </ol>
                    </li>
                  </ol>

                  <h2 style={{marginTop: `20px`, marginBottom: `20px`}}>11. Payment Methods</h2>
                  <p>
                    Payment for goods or services purchased from the "Mall" can
                    be made through any available method among the following.
                    However, the "Mall" cannot collect any additional fees under
                    any name for the payment method chosen by users:
                  </p>
                  <ul>
                    <li>
                      Various account transfers such as phone banking, internet
                      banking, mail banking
                    </li>
                    <li>
                      Various card payments such as prepaid cards, debit cards,
                      credit cards
                    </li>
                    <li>Online bank deposit</li>
                    <li>Payment by electronic money</li>
                    <li>Payment upon receipt</li>
                    <li>
                      Payment by points such as mileage issued by the "Mall"
                    </li>
                    <li>
                      Payment by gift certificates contracted with or recognized
                      by the "Mall"
                    </li>
                    <li>Payment by other electronic payment methods</li>
                  </ul>

                  <h2 style={{marginTop: `20px`, marginBottom: `20px`}}>
                    12. Receipt Confirmation Notice, Change and Cancellation of
                    Purchase Request
                  </h2>
                  <ol>
                    <li>
                      The "Mall" shall send a receipt confirmation notice to
                      users when there is a purchase request.
                    </li>
                    <li>
                      Users who receive the receipt confirmation notice can
                      request changes or cancellation of the purchase request
                      immediately after receiving the notice if there is any
                      discrepancy in the expression of intention, and the "Mall"
                      shall process such requests without delay if received
                      before shipping. However, if payment has already been
                      made, it shall be governed by the provisions on withdrawal
                      of subscription in Article 15.
                    </li>
                  </ol>

                  <h2 style={{marginTop: `20px`, marginBottom: `20px`}}>13. Supply of Goods</h2>
                  <ol>
                    <li>
                      Unless there is a separate agreement regarding the timing
                      of supply of goods with users, the "Mall" shall take
                      necessary measures such as custom production and packaging
                      to enable shipping of goods within 7 days from the date of
                      subscription. However, when the "Mall" has already
                      received all or part of the payment for goods, it shall
                      take measures within 3 business days from the date of
                      receiving the payment. In this case, the "Mall" shall take
                      appropriate measures to allow users to check the supply
                      procedure and progress of goods.
                    </li>
                    <li>
                      The "Mall" shall specify the means of delivery, bearer of
                      delivery costs by means, and delivery period by means for
                      goods purchased by users. If the "Mall" exceeds the agreed
                      delivery period, it shall compensate for damages to users.
                      However, this shall not apply if the "Mall" proves that
                      there was no intention or negligence.
                    </li>
                  </ol>

                  <h2 style={{marginTop: `20px`, marginBottom: `20px`}}>14. Refund</h2>
                  <p>
                    When the "Mall" cannot deliver or provide goods requested by
                    users due to reasons such as being out of stock, it shall
                    notify users of the reason without delay and refund or take
                    necessary measures for refund within 3 business days from
                    the date of receiving payment if payment was received in
                    advance.
                  </p>

                  <h2 style={{marginTop: `20px`, marginBottom: `20px`}}>15. Withdrawal of Subscription</h2>
                  <ol>
                    <li>
                      Users who have concluded contracts for purchase of goods
                      with the "Mall" may withdraw their subscription within 7
                      days from the date of receiving the written contract
                      contents pursuant to Article 13, Paragraph 2 of the "Act
                      on Consumer Protection in Electronic Commerce" (referring
                      to the date of receiving goods or the date when supply of
                      goods began if the supply of goods occurred later than
                      receiving the written document). However, if there are
                      different provisions regarding withdrawal of subscription
                      in the "Act on Consumer Protection in Electronic
                      Commerce," those provisions shall apply.
                    </li>
                    <li>
                      Users cannot return or exchange goods in any of the
                      following cases after receiving them:
                      <ol>
                        <li>
                          When goods are destroyed or damaged due to reasons
                          attributable to users (however, withdrawal of
                          subscription is possible if packaging was damaged to
                          check the contents of goods)
                        </li>
                        <li>
                          When the value of goods has significantly decreased
                          due to use or partial consumption by users
                        </li>
                        <li>
                          When the value of goods has significantly decreased
                          making resale difficult due to the passage of time
                        </li>
                        <li>
                          When the packaging of original goods that can be
                          reproduced with goods of the same performance is
                          damaged
                        </li>
                      </ol>
                    </li>
                    <li>
                      Despite cases 2 through 4 of Paragraph 2, if the "Mall"
                      did not clearly indicate that withdrawal of subscription
                      would be restricted in a place easily recognizable by
                      consumers or did not provide trial products, users'
                      withdrawal of subscription shall not be restricted.
                    </li>
                    <li>
                      Notwithstanding Paragraphs 1 and 2, users can withdraw
                      subscription within 3 months from the date of receiving
                      goods, or within 30 days from the date they knew or could
                      have known the fact, if the contents of goods differ from
                      display/advertisement contents or if the contract was
                      performed differently from its contents.
                    </li>
                  </ol>
                  <h2 style={{marginTop: `20px`, marginBottom: `20px`}}>16. Effects of Withdrawal of Subscription</h2>
                  <p>
                    ① When the "Mall" receives returned goods from users, it
                    shall refund the payment already received for the goods
                    within 3 business days. In this case, if the "Mall" delays
                    the refund to users, it shall pay delay interest calculated
                    by multiplying the delay interest rate prescribed in Article
                    21-2 of the Enforcement Decree of the "Act on Consumer
                    Protection in Electronic Commerce" for the delay period.
                  </p>
                  <p>
                    ② When making the above refund, if users paid for goods
                    using credit cards or electronic money, the "Mall" shall
                    request the business operator who provided the payment
                    method to stop or cancel the payment claim for the goods
                    without delay.
                  </p>
                  <p>
                    ③ In case of withdrawal of subscription, users shall bear
                    the costs necessary for returning the received goods. The
                    "Mall" shall not claim penalties or damages from users due
                    to withdrawal of subscription. However, if withdrawal of
                    subscription is made because the contents of goods differ
                    from display/advertisement contents or the contract was
                    performed differently, the "Mall" shall bear the costs
                    necessary for returning the goods.
                  </p>
                  <p>
                    ④ If users paid shipping fees when receiving goods, the
                    "Mall" shall clearly indicate who bears those costs in case
                    of withdrawal of subscription in a way that users can easily
                    understand.
                  </p>
                  <h2 style={{marginTop: `20px`, marginBottom: `20px`}}>17. Protection of Personal Information</h2>
                  <p>
                    ① The "Mall" collects minimum personal information necessary
                    for service provision. The following items are collected as
                    required information, while other items are optional:
                  </p>
                  <ul>
                    <li>Name</li>
                    <li>
                      Resident registration number (for members) or foreign
                      registration number
                    </li>
                    <li>Address</li>
                    <li>Phone number</li>
                    <li>ID (for members)</li>
                    <li>Password (for members)</li>
                    <li>Email address</li>
                  </ul>
                  <p>
                    ② The "Mall" must obtain consent from users when collecting
                    personally identifiable information.
                  </p>
                  <p>
                    ③ The "Mall" cannot use personal information provided by
                    users for purposes other than agreed upon or provide it to
                    third parties without consent, and the "Mall" is responsible
                    for this. However, the following cases are exceptions:
                  </p>
                  <ol>
                    <li>
                      When providing minimum user information (name, address,
                      phone number) necessary for delivery to shipping companies
                    </li>
                    <li>
                      When providing to hosting companies for member management,
                      system management, and information provision
                    </li>
                    <li>
                      When providing in a form that cannot identify specific
                      individuals for statistics, academic research, or market
                      research
                    </li>
                    <li>
                      When necessary for payment settlement related to
                      transactions
                    </li>
                    <li>
                      When identity verification is needed to prevent
                      impersonation or fraud
                    </li>
                    <li>
                      When required by law or when there are unavoidable reasons
                      under law
                    </li>
                  </ol>
                  <p>
                    ④ When the "Mall" needs to obtain user consent under
                    Paragraphs 2 and 3, it must specify or notify in advance the
                    identity of the personal information manager (affiliation,
                    name, phone number, other contact information), purpose of
                    collection and use, and matters related to third-party
                    information provision (recipient, purpose, and content of
                    information to be provided) as stipulated in Article 22,
                    Paragraph 2 of the "Act on Promotion of Information and
                    Communications Network Utilization and Information
                    Protection," and users may withdraw this consent at any
                    time.
                  </p>
                  <p>
                    ⑤ Users may request access to and correction of errors in
                    their personal information held by the "Mall" at any time,
                    and the "Mall" has the obligation to take necessary measures
                    without delay. When users request error correction, the
                    "Mall" shall not use the personal information until the
                    error is corrected.
                  </p>
                  <p>
                    ⑥ The "Mall" must minimize the number of people handling
                    users' personal information for protection and is
                    responsible for all damages to users caused by loss, theft,
                    leakage, unauthorized third-party provision, or alteration
                    of users' personal information, including credit cards and
                    bank accounts.
                  </p>
                  <p>
                    ⑦ The "Mall" or third parties who received personal
                    information shall destroy the personal information without
                    delay when the purpose of collection or provision is
                    achieved:
                  </p>
                  <ul>
                    <li>
                      Membership information: When membership is withdrawn or
                      terminated
                    </li>
                    <li>
                      Payment information: When payment is completed or the
                      statute of limitations expires
                    </li>
                    <li>
                      Delivery information: When goods or services are delivered
                      or provided
                    </li>
                    <li>
                      Information collected for surveys, events, etc.: When the
                      survey or event ends
                    </li>
                  </ul>
                  <p>
                    However, personal information may be retained even after
                    achieving the purpose of collection or provision if
                    preservation is necessary under related laws such as the
                    Commercial Act:
                  </p>
                  <ul>
                    <li>
                      Records on contracts or withdrawal of subscription: 5
                      years
                    </li>
                    <li>Records on payment and supply of goods: 5 years</li>
                    <li>
                      Records on consumer complaints or dispute handling: 3
                      years
                    </li>
                  </ul>
                  <p>
                    ⑧ The "Mall" shall not preset consent boxes for collection,
                    use, and provision of personal information. Additionally, it
                    shall specifically indicate services that are restricted
                    when users refuse to consent to collection, use, and
                    provision of personal information, and shall not restrict or
                    refuse service provision such as membership registration due
                    to users' refusal to consent to collection, use, and
                    provision of non-essential personal information.
                  </p>

                  <h2 style={{marginTop: `20px`, marginBottom: `20px`}}>18. Obligations of the "Mall"</h2>
                  <p>
                    ① The "Mall" shall not engage in acts prohibited by laws and
                    these terms or contrary to public order and morals, and
                    shall do its best to provide goods and services continuously
                    and reliably in accordance with these terms.
                  </p>
                  <p>
                    ② The "Mall" must have security systems in place to protect
                    users' personal information (including credit information)
                    so that users can safely use internet services.
                  </p>
                  <p>
                    ③ The "Mall" is responsible for compensating damages if
                    users suffer losses due to unfair display or advertising
                    practices under Article 3 of the "Act on Fair Labeling and
                    Advertising."
                  </p>
                  <p>
                    ④ The "Mall" shall not send commercial advertising emails
                    that users do not want.
                  </p>

                  <h2 style={{marginTop: `20px`, marginBottom: `20px`}}>19. Obligations Regarding Member ID and Password</h2>
                  <p>
                    ① Members are responsible for managing their ID and password
                    except in cases under Article 17.
                  </p>
                  <p>
                    ② Members shall not allow third parties to use their ID and
                    password.
                  </p>
                  <p>
                    ③ When members become aware that their ID and password have
                    been stolen or are being used by third parties, they must
                    immediately notify the "Mall" and follow the "Mall's"
                    guidance if provided.
                  </p>

                  <h2 style={{marginTop: `20px`, marginBottom: `20px`}}>20. User Obligations</h2>
                  <p>Users shall not engage in the following acts:</p>
                  <ul>
                    <li>
                      Registering false information when applying or making
                      changes
                    </li>
                    <li>Stealing others' information</li>
                    <li>Modifying information posted on the "Mall"</li>
                    <li>
                      Transmitting or posting information (such as computer
                      programs) other than that specified by the "Mall"
                    </li>
                    <li>
                      Infringing on copyrights and other intellectual property
                      rights of the "Mall" or third parties
                    </li>
                    <li>
                      Damaging the reputation of or interfering with the
                      business of the "Mall" or third parties
                    </li>
                    <li>
                      Publicly disclosing or posting obscene or violent
                      messages, images, sounds, or other information contrary to
                      public order and morals on the "Mall"
                    </li>
                  </ul>

                  <h2 style={{marginTop: `20px`, marginBottom: `20px`}}>
                    21. Relationship Between Linked "Mall" and Linked-to "Mall"
                  </h2>
                  <p>
                    ① When upper and lower "Malls" are connected through
                    hyperlinks (including text, images, and animations), the
                    former is called the linking "Mall" (website) and the latter
                    is called the linked-to "Mall" (website).
                  </p>
                  <p>
                    ② The linking "Mall" is not responsible for guaranteeing
                    transactions between users and the linked-to "Mall"
                    regarding goods independently provided by the linked-to
                    "Mall" if this is specified on the initial screen of the
                    linking "Mall" or in a pop-up screen at the time of
                    connection.
                  </p>

                  <h2 style={{marginTop: `20px`, marginBottom: `20px`}}>22. Attribution and Restriction of Copyright</h2>
                  <p>
                    ① Copyright and other intellectual property rights for works
                    created by the "Mall" belong to the "Mall."
                  </p>
                  <p>
                    ② Users shall not use information obtained through using the
                    "Mall" that is subject to the "Mall's" intellectual property
                    rights for commercial purposes or allow third parties to use
                    it through reproduction, transmission, publication,
                    distribution, broadcasting, or other methods without prior
                    consent from the "Mall."
                  </p>
                  <p>
                    ③ The "Mall" must notify users when using copyrights
                    belonging to users according to agreement.
                  </p>

                  <h2 style={{marginTop: `20px`, marginBottom: `20px`}}>23. Dispute Resolution</h2>
                  <p>
                    ① The "Mall" shall establish and operate a damage
                    compensation processing organization to reflect legitimate
                    opinions or complaints raised by users and process
                    compensation for damages.
                  </p>
                  <p>
                    ② The "Mall" shall prioritize handling complaints and
                    opinions submitted by users. However, when prompt handling
                    is difficult, the "Mall" shall immediately notify users of
                    the reason and processing schedule.
                  </p>
                  <p>
                    ③ When users request relief for damages related to
                    e-commerce disputes between the "Mall" and users, the matter
                    may be mediated by dispute mediation institutions
                    commissioned by the Fair Trade Commission or city/provincial
                    governors.
                  </p>
                  <h3 style={{marginTop: `20px`, marginBottom: `20px`}}>24. Disclaimer</h3>
                  <ol>
                    <li>
                      The "Mall" is exempt from responsibility for service
                      provision when unable to provide services due to force
                      majeure such as natural disasters, war, and other similar
                      circumstances.
                    </li>
                    <li>
                      The "Mall" is exempt from responsibility when damages
                      occur due to telecommunications service providers stopping
                      or not properly providing telecommunications services.
                    </li>
                    <li>
                      The "Mall" is exempt from responsibility for damages
                      caused by unavoidable reasons such as maintenance,
                      replacement, regular inspection, or construction of
                      service facilities.
                    </li>
                    <li>
                      The "Mall" is not responsible for service use obstacles or
                      damages caused by users' fault.
                    </li>
                    <li>
                      The "Mall" is not responsible for damages caused by errors
                      in users' computers, devices, etc., or damages caused by
                      members providing incomplete personal information and
                      email addresses.
                    </li>
                    <li>
                      The "Mall" is not responsible for users' failure to obtain
                      or loss of expected profits from using the service.
                    </li>
                    <li>
                      The "Mall" is not responsible for damages caused by
                      materials obtained by users while using the service.
                      Additionally, the "Mall" is not responsible for
                      compensating psychological damage suffered by users due to
                      other users while using the service.
                    </li>
                    <li>
                      The "Mall" has no obligation to intervene in disputes
                      arising between users or between users and third parties
                      through the service, and is not responsible for
                      compensating any resulting damages.
                    </li>
                  </ol>

                  <h3 style={{marginTop: `20px`, marginBottom: `20px`}}>25. Jurisdiction and Governing Law</h3>
                  <ol>
                    <li>
                      Lawsuits related to e-commerce disputes between the "Mall"
                      and users shall be under the exclusive jurisdiction of the
                      district court having jurisdiction over the user's address
                      at the time of filing, or residence if there is no
                      address. However, if the user's address or residence is
                      unclear at the time of filing or the user is a foreign
                      resident, the lawsuit shall be filed with the court having
                      jurisdiction under the Civil Procedure Act.
                    </li>
                    <li>
                      Lawsuits between the company and users shall be governed
                      by the laws of the Republic of Korea.
                    </li>
                    <li>
                      Matters not specified in these terms shall be governed by
                      related laws such as the Telecommunications Business Act
                      and commercial practices.
                    </li>
                  </ol>

                  <h3>Addendum</h3>
                  <p>These Terms of Use are effective from December 2, 2024.</p>
                </div>
        )}
     


        </div>
        </div>
        </div>
    )
}