import { create } from "zustand";
import { persist } from "zustand/middleware";



export const useURLStore = create(
    persist(
        (set) => ({
            language: "ko",
            setLanguage: (newLanguage) => set({language: newLanguage}),
            resetLanguage : () => set({language: "ko"}),
        }),
        {
            name: "current-language-storeage",
            getStorage: () => localStorage,
        }
    )
)